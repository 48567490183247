$small: 500px;
$mobile: 768px;
$tablets: 992px;
$desktops: 1200px;
$widescreen: 1600px;

@mixin respond-to($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile) { @content; }
  }
  @else if $media == tablets {
    @media only screen and (min-width: $mobile + 1) and (max-width: $tablets - 1) { @content; }
  }
  @else if $media == tabledesktop {
    @media only screen and (min-width: $tablets + 1) and (max-width: $desktops - 1) { @content; }
  }
  @else if $media == desktops {
    @media only screen and (min-width: $tablets) {  @content; }
  }
    @else if $media == desktopsless {
    @media only screen and (max-width: $desktops) { @content; }
  }
  @else if $media == small {
    @media only screen and (max-width: $small) { @content; }
  }
  @else if $media == mobilemore {
    @media only screen and (min-width: $mobile) { @content; }
  }
  @else if $media == tabletsmore {
    @media only screen and (min-width: $tablets) { @content; }
  }
  @else if $media == wide {
    @media only screen and (min-width: $widescreen) { @content; }
  }
  @else if $media == wideless {
    @media only screen and (max-width: $widescreen) { @content; }
  }
  @else if $media == navbarlanding {
    @media only screen and (max-width: 1050px) { @content; }
  }
}

@mixin whitelink{
  color:#fff;
  border-bottom: 1px solid rgba(255, 255, 255, .2);
  text-decoration: none;
  &:hover{
    color: #fff;
    border: none !important;
    text-decoration: none;
  }
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}