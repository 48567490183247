@import "media";


/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper{position:absolute; top:0; left:0; z-index:9999; overflow:hidden; -webkit-transform: translate3d(0,0,0);}
#cboxWrapper {max-width:none;}
#cboxOverlay{position:fixed; width:100%; height:100%;}
#cboxMiddleLeft, #cboxBottomLeft{clear:left;}
#cboxContent{position:relative;}
#cboxLoadedContent{overflow:auto; -webkit-overflow-scrolling: touch;}
#cboxTitle{margin:0;}
#cboxLoadingOverlay, #cboxLoadingGraphic{position:absolute; top:0; left:0; width:100%; height:100%;}
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow{cursor:pointer;}
.cboxPhoto{float:left; margin:auto; border:0; display:block; max-width:none; -ms-interpolation-mode:bicubic;}
.cboxIframe{width:100%; height:100%; display:block; border:0; padding:0; margin:0;}
#colorbox, #cboxContent, #cboxLoadedContent{box-sizing:content-box; -moz-box-sizing:content-box; -webkit-box-sizing:content-box;}

/*
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay{background:#000; opacity: 0.9; filter: alpha(opacity = 90);}
#colorbox{outline:0;}
    #cboxContent{margin-top:40px;background:#000;}
        .cboxIframe{background:#fff;}
        #cboxError{padding:50px; border:1px solid #ccc;}
        #cboxLoadedContent{border:5px solid #000; background:#fff;}
        #cboxTitle{position:absolute; top:-20px; left:0; color:#ccc;}
        #cboxCurrent{position:absolute; top:-20px; right:0px; color:#ccc;}
        #cboxLoadingGraphic{background:url(/static/images/colorbox/loading.gif) no-repeat center center;}

        /* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
        #cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {border:0; padding:0; margin:0; overflow:visible; width:auto; background:none; }

        /* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
        #cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {outline:0;}

        #cboxSlideshow{position:absolute; top:-20px; right:90px; color:#fff;}
        #cboxPrevious{position:absolute; top:50%; left:5px; margin-top:-32px; background:url(/static/images/colorbox/controls.png) no-repeat top left; width:33px; height:45px; text-indent:-9999px;}
        #cboxPrevious:hover{background-position:bottom left;}
        #cboxNext{position:absolute; top:50%; right:5px; margin-top:-32px; background:url(/static/images/colorbox/controls.png) no-repeat top right; width:33px; height:45px; text-indent:-9999px;}
        #cboxNext:hover{background-position:bottom right;}
        #cboxClose{position:absolute; top:10px; right:15px; display:block; background:url(/static/images/colorbox/controls.png) no-repeat top center; width:32px; height:27px; text-indent:-9999px;}
        #cboxClose:hover{background-position:bottom center;}


figure.hoverable-block {
  position: relative;
  //float: left;
  overflow: hidden;
  margin: 0;
  width: 100%;
  color: #000000;
  text-align: center;
  -webkit-perspective: 50em;
  perspective: 50em;
  .hoverable-block__logo{
    max-width: 100%;
    height: 20px;
  }
  .dots-divider{
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
}
figure.hoverable-block * {
  -webkit-box-sizing: padding-box;
  box-sizing: padding-box;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
figure.hoverable-block img {
  max-width: 100%;
  vertical-align: top;
  @include respond-to(mobile){
    display: none;
  }
}
figure.hoverable-block figcaption {
  top: 50%;
  left: 20px;
  right: 20px;
  position: absolute;
  opacity: 0;
  z-index: 1;
}
figure.hoverable-block h2,
figure.hoverable-block h4 {
  margin: 0;
}
figure.hoverable-block h2 {
  font-weight: 600;
}
figure.hoverable-block h4 {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 15px;
}
figure.hoverable-block i {
  font-size: 32px;
}
figure.hoverable-block:after {
  background-color: #ffffff;
  position: absolute;
  content: "";
  display: block;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  opacity: 0;
}
figure.hoverable-block a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 1;
}
figure.hoverable-block:hover figcaption,
figure.hoverable-block.hover figcaption {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 1;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
figure.hoverable-block:hover:after,
figure.hoverable-block.hover:after {
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
  opacity: 0.9;
}


.mouse {
	position: absolute;
	width: 36px;
	height: 60px;
	bottom: 90px;
	left: 50%;
	margin-left: -12px;
	border-radius: 15px;
	border: 2px solid #eaeaea;
	animation: intro 1s;
}

.scroll {
	display: block;
	width: 3px;
	height: 3px;
	margin: 6px auto;
	border-radius: 4px;
	background: #eaeaea;
	animation: finger 1s infinite;
}

@-moz-keyframes intro {
	0% {
		opacity: 0;
		transform: translateY(40px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@-webkit-keyframes intro {
	0% {
		opacity: 0;
		transform: translateY(40px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@-o-keyframes intro {
	0% {
		opacity: 0;
		transform: translateY(40px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes intro {
	0% {
		opacity: 0;
		transform: translateY(40px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@-moz-keyframes finger {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: translateY(20px);
	}
}

@-webkit-keyframes finger {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: translateY(20px);
	}
}

@-o-keyframes finger {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: translateY(20px);
	}
}

@keyframes finger {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: translateY(20px);
	}
}

@-webkit-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@-moz-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}



$accounting: #ebb542;
$it: #333;
$law: #6eb53f;
$finance: #487aa0;
$analytics: #f38227;
$pr: #df3b4e;
$sales: #ad3e38;
$greybg: #f1f1f1;
$greybg2 : #e8ecf6;
$blue : #4a7ba9;
$link: #179BD7;
$brdblue: #ccd8e2;

@import "media";
@import "font";


section{
  background: #ffffff;
}

body{
  background: #fff;
  font-family: 'Raleway' ,'Open Sans', 'HelveticaNeueCyr', 'Arial', sans-serif;
  font-weight: normal;
  font-size: 16px;
  scroll-behavior: smooth !important;
}

#header{
  background: #111111;
  border-bottom:none;
}

.y-scroll{
  overflow-y: scroll;
  max-height: 100vh;
}

table a{
  color:$link;
}

a{
  text-decoration: underline;
  color:$link;
  transition:all ease .3s;
  &:hover{
    color:lighten($link, 15%);
    text-decoration: none;
    transition:all ease .3s;
  }
}

.greybg2{
  background: $greybg2;
}

.preloader{
	&-content{
	}
	&-wrapper{
		position: relative;
	}
	background: rgba(255,255,255,.95);
	position: absolute;
	bottom: 0;
	top: 0;
	right: 0;
	left: 0;
	display: flex;
	z-index:100;
	min-height: 200px;
	&-title{
		margin-top: 30px;
	}
}

/* Demo Styles */
#content {
  margin: 0 auto;
  padding-bottom: 50px;
  width: 80%;
  max-width: 978px;
}

h1 {
  font-size: 40px;
}



.dropdown-menu.has-topBar{
  >li>.row{
    @include respond-to(desktopsless){
      padding-left: 50px !important;
      padding-right: 50px !important;
    }
    @include respond-to(tablets){
      padding-left: 3% !important;
      padding-right: 3% !important;
    }
    @include respond-to(mobile){
      padding-left: 3% !important;
      padding-right: 3% !important;
    }
    padding-left: 100px !important;
    padding-right: 100px !important;
    }
}

.header-container{
  @include respond-to(desktopsless){
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  @include respond-to(tablets){
    padding-left: 3% !important;
    padding-right: 3% !important;
  }
  @include respond-to(mobile){
    padding-left: 3% !important;
    padding-right: 3% !important;
  }
  padding-left: 100px !important;
  padding-right: 100px !important;
  width: 100% !important;
}

header a.logo>img{
  width: 250px;
  height: auto;
}

#header a.logo{
  @include respond-to(tablets){
    float:right !important;
  }
  @include respond-to(mobile){
    float:right !important;
  }
}


.social-instagram{
      background: #d6249f;
      background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
      &:hover{
        background: #d6249f;
        background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
      }
}


.social-icons-horizontal{
  padding: 12px 0 0;
  @include respond-to(mobile){
    padding-top: 2px;
  }
  @include respond-to(tablets){
    padding-top: 6px;
  }
  i{
    color:#fff;
  }
  .social-icon{
    margin-bottom: 0;
  }
}



#header.fixed{
  border-bottom:none;
  box-shadow: 5px 0 6px rgba(0,0,0,.4)!important;
  .social-icons-horizontal{
    padding-top: 5px;
  }
}




.navbar-logo{
  height: 30px;
  float: left;
  visibility: hidden;
  overflow:hidden;
  width: 0;
  transition:width .3s linear;
  margin-right: 0;
}


#header.fixed{
  .navbar-logo{
    height: 36px;
    float: left;
    visibility: visible;
    overflow:hidden;
    width: 36px;
    transition:width .3s linear;
    position: relative;
    top: 4px;
    margin-right: 20px;
  }
}


.header{
  &__contact-item{
    display: inline-block;
    margin-right: 20px;
    &:last-child{
      margin-right: 0;
    }
    &__list{
      list-style-type: none;
      margin: 0;
      border:none;
      padding:30px 0 20px;
      display: block;
      @include respond-to(mobile){
        float: none !important;
        padding: 10px 0;
        display: block;
      }
      @include respond-to(small){
        display: none;
      }
    }
    &__list-sidepanel{
      list-style-type: none;
      margin: 0;
      border:0 !important;
      padding:30px 0 20px;
      @include respond-to(mobile){
        float: none !important;
        padding: 10px 0;
      }

    }
    &__icon{
      display: inline-block;
      float: left;
      width: 35px;
      height: 35px;
      text-align: center;
      border:2px solid $blue;
      text-decoration: none;
      transition:all ease .15s;
      &:hover{
        transition:all ease .15s;
        transform:scale(1.05,1.05);
        cursor:pointer;
      }
      i{
        font-size: 18px;
        line-height: 35px !important;
        color:$blue;
      }
    }
    &__text-content{
      float: left;
      padding-left: 10px;
      text-align: left;
      //padding-top: 5px;
    }
    &__text{
      font-size: 17px;
      text-align: left;
      position: relative;
      top:-5px;
      text-decoration: none;
      text-transform: none !important;
    }
    &__label{
      font-size: 12px;
      color:#777;
      text-align: left;
    }

  }
}

.ctc__content-inner__bottom__inner-copy{
  display: none !important;
}


.main-banner{
  min-height:750px;
  background: #444;
  position: relative;
  -webkit-background-size:cover !important;
  background-size:cover !important;
  padding-bottom: 50px;
  &__content{
    padding-top: 28%;
  }
  &__h{
    color: #fff;
    margin-bottom: 40px;
  }
  &__intro{
    color: #fff;
    font-size: 25px;
    font-weight: 300;
    margin-bottom: 80px;
  }
  &_index{

  }
}



.common{
  &-text{
    &_big{
      font-size: 1.3em;
    }
  }
}

.navigation{
  &.fixed{
    background: #1e2023;
  }
}

.footer{
  background: #1e2023;
  .social-share{
    background: #292b2d;
  }
  &-group{
    border-top: solid 1px #2f3135 !important;
  }
  &-logo{
    height: auto !important;
    max-width: 100%;
  }
  h1,h2,h3,h4,h5{
    font-family:'Raleway';
    text-transform: uppercase;
  }
  &__column{
    margin-bottom: 50px;
  }
}

.logo{
  &-img{
    max-width:100px;
    height: 55px;
  }
}

a{
  transition:all ease .3s;
  &:hover{
    transition:all ease .3s;
  }
}

.link-underline{
  border-bottom-width: 1px;
  border-bottom-style: solid;
  &:hover{
    border-bottom-color:rgba(0,0,0,0);
  }
}

.main-banner-service{
  display: inline-block;
  margin-right:15px;
  max-width: 150px;
  vertical-align: top;
  &:last-child{
    margin-right: 0;
  }
  &__title{
    display: block;
    text-transform: uppercase;
    margin-top: 10px;
    font-size: 1.1em;
    &_accounting{
      color:$accounting;
    }
    &_it{
      color:$it;
    }
    &_law{
      color:$law;
    }
    &_finance{
      color:$finance;
    }
    &_sales{
      color:$sales;
    }
    &_pr{
      color:$pr;
    }
    &_analytics{
      color:$analytics;
    }
  }
  &__icon{
    //max-width: 100%;
    -webkit-border-radius:50%;
    -moz-border-radius:50%;
    border-radius:50%;
    border:2px solid;
    width: 128px;
    height: 128px;
    display: inline-block;
    &_accounting{
      border-color:$accounting;
    }
    &_it{
      border-color:$it;
    }
    &_law{
      border-color:$law;
    }
    &_finance{
      border-color:$finance;
    }
    &_sales{
      border-color:$sales;
    }
    &_pr{
      border-color:$pr;
    }
    &_analytics{
      border-color:$analytics;
    }
    &-img{
      padding: 30px;
      display: block;
      max-width: 100%;
    }
    &-wrapper{

    }
  }
}

#topNav #topMain>li.mega-menu div.row div>ul>li>span{
  //text-transform: uppercase;
}

#topNav ul.dropdown-menu li a{
  //text-transform: none !important;
  padding-right: 30px !important;
}

#topBar a.logo {
    height: 100px;
    line-height: 100px;
    overflow: hidden;
    display: inline-block;
  @include respond-to(mobile){
    float:none !important;
    display: block;
    margin: 0 auto;
    height: auto;
    line-height: normal;
    padding: 20px 0;
    img{
      width: 200px;
    }
  }
}


.categories-alt{
  &__column{
    text-align: center;
    cursor:pointer;
    padding: 40px;
    text-decoration: none;
    transition:all ease .5s;
    &:hover{
      transform:scale(1.02,1.02);
      z-index:100;
      box-shadow:0 0 15px rgba(0,0,0,.7);
    }
    img{
      margin: 20px auto 15px;
      max-width: 100%;
      width: 60px;
      @include respond-to(wideless){
        width: 60px;
      }
    }
  }
  &__h{
    color:#fff;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 7px;
    text-decoration: none;
    @include respond-to(wideless){
      font-size: 18px;
    }
  }
  &__desc{
    color:#f0f0f0;
    font-size: 13px;
    text-decoration: none;
    @include respond-to(wideless){
      font-size: 13px;
    }
  }
  .bgcolor{
      &_finance{
        background:$finance !important;
        transition:all ease .3s;
        &:hover{
          background: darken($finance, 6%) !important;
          transition:all ease .3s;
        }
      }
      &_sales{
          background:$sales !important;
          transition:all ease .3s;
          &:hover{
            background: darken($sales, 6%) !important;
            transition:all ease .3s;
          }
        }
      &_it{
        background:$it !important;
        transition:all ease .3s;
        &:hover{
          background: darken($it, 6%) !important;
          transition:all ease .3s;
        }
      }
      &_accounting{
        background:$accounting !important;
        transition:all ease .3s;
        &:hover{
          background: darken($accounting, 6%) !important;
          transition:all ease .3s;
        }
      }
      &_analytics{
        background:$analytics !important;
        transition:all ease .3s;
        &:hover{
          background: darken($analytics, 5%) !important;
          transition:all ease .3s;
        }
      }
      &_law{
        background:$law !important;
        transition:all ease .3s;
        &:hover{
          background: darken($law, 6%) !important;
          transition:all ease .3s;
        }
      }
      &_pr{
        background:$pr !important;
        transition:all ease .3s;
        &:hover{
          background: darken($pr, 6%) !important;
          transition:all ease .3s;
        }
      }
    }
}

.color{
  &_finance{
      color:$finance !important;
  }
  &_sales{
      color:$sales !important;
  }
  &_it{
    color:$it !important;
  }
  &_analytics{
    color:$analytics !important;
  }
  &_accounting{
    color:$accounting !important;
  }
  &_law{
    color:$law !important;
  }
  &_pr{
    color:$pr !important;
  }
}

.bgcolor{
  &_finance{
    background:$finance !important;
  }
  &_sales{
    background:$sales !important;
  }
  &_it{
    background:$it !important;
  }
  &_analytics{
    background:$analytics !important;
  }
  &_accounting{
    background:$accounting !important;
  }
  &_law{
    background:$law !important;
  }
  &_pr{
    background:$pr !important;
  }
}

.border-color{
  &_finance{
    border-color:rgba($finance,0.3) !important;
  }
  &_sales{
    border-color:rgba($sales,0.3) !important;
  }
  &_it{
    border-color:rgba($it,0.3) !important;
  }
  &_analytics{
    border-color:rgba($analytics,0.3) !important;
  }
  &_accounting{
    border-color:rgba($accounting,0.3) !important;
  }
  &_law{
    border-color:rgba($law,0.3) !important;
  }
  &_pr{
    border-color:rgba($pr,0.3) !important;
  }
}

.categories{
  padding: 30px 0;
}

.topimg{
  &__h{
    //padding:0 30px;
    display: block;
    text-align: left !important;
    font-size: 42px;
    text-transform: uppercase;
    font-family:'Raleway';
    @include respond-to(mobile){
      font-size: 30px !important;
    }
  }
  &__separator{
    height: 6px;
    width: 130px;
    display: block;
    background: $blue;
  }
  &__subh{
    margin-top: 15px;
    font-weight: normal;
    width: 70%;
    @include respond-to(mobile){
      width: 100%;
    }
    //text-transform: uppercase;
  }
}

.category-block{
  //border:1px solid #d9d9d9;
  -webkit-border-radius:5px;
  -moz-border-radius:5px;
  border-radius:5px;
  position: relative;
  padding: 10px 20px;
  margin-bottom: 30px;
  background: #fff;
  &-wrapper{
    height: auto !important;
    margin-bottom: 0 !important;
  }
  &__icon_wrapper{

  }
  &__icon{
    width: 30px;
    position: absolute;
    right: 10px;
    top: 10px;
    //float: right;
  }
  &__title{
    font-size: 1.6em;
    display: block;
    margin-bottom: 20px;
    font-weight: 400;
    letter-spacing: -1px;
    &_finance{
      color:$finance;
    }
    &_sales{
      color:$sales;
    }
    &_it{
      color:$it;
    }
    &_accounting{
      color:$accounting;
    }
    &_law{
      color:$law;
    }
    &_pr{
      color:$pr;
    }
  }
  &__list{
    padding-left: 0;
  }
  &__li{
    display: block;
    margin-bottom: 10px;
  }
  &__link{
    color:#a3a7aa;
    text-decoration: none;
    border-bottom-color: #ccc;
    @extend .link-underline
  }
}

.price-clean{
  h4{
    font-size: 30px;
    sup{
      top: 10px;
    }
  }
}

#footer{
      background: #fff !important;
}

.product-box{
  //border:1px solid #ddd;
  padding: 30px 30px 0;
  background: #fff;
  height: 100%;
  position: relative;
  @include respond-to(mobilemore){
    margin-bottom: 0;
  }

  &__h{
    margin-bottom: 0;
  }
  &__list{
    list-style-type: none;
    padding-left: 0;
  }
  i{
    font-size: 16px;
  }
  &__li{
    display: block;
    padding: 8px 0;
    border-bottom: 1px dashed #ddd;
    font-size: 20px;
    color: #777;
    font-weight: 400;
    @include respond-to(tabledesktop){
      font-size: 16px;
    }
    @include respond-to(small){
      font-size: 16px;
    }
    &:last-child{
      border-bottom:none;
    }
  }
  &__total{

  }
  &__buy-btn{
    display: block;
    padding: 15px;
    text-align: center;
    color:#fff;
    font-size: 14px;
    text-transform: uppercase;
    cursor:pointer;
    //margin:0 -30px;
    cursor:pointer;
    @include respond-to(tablets){
      margin-bottom: 30px;
    }
    @include respond-to(mobile){
      margin-bottom: 30px;
    }
  }
  &__price{
    text-align: center;
    //margin:0 -30px;
    background: #444;
    padding:20px 10px;
    &__title{
      font-size: 14px;
      color:#bbb;
    }
    &__sum{
      font-size: 30px;
      color:#fff;
    }
  }
}

#toTop{
  height: auto !important;
  width: auto !important;
  padding: 10px;
  right: auto;
  left:10px;
  bottom:25px;
}

.own-product{
  background: #fff;
  //padding: 30px;
  &__h{
    margin-bottom: 10px;
  }
  &__desc{
    color:#777;
    font-size: 14px !important;
  }
  &__ul{
    margin:20px 0;
    padding: 0 !important;
  }
  &__li{
    display: block;
    padding: 8px 10px;
    border-bottom: 1px dashed #ddd;
    font-size: 20px;
    color: #777;
    font-weight: 400;
    @include respond-to(tabledesktop){
      font-size: 16px;
    }
    @include respond-to(small){
      font-size: 16px;
    }
    &:last-child{
      border-bottom:none;
    }
  }
  &__total{
    border-top:3px dashed #777;
    text-align: center;
    &__title{
      font-size: 24px;
      display: block;
      padding: 10px 0;
      span{
        font-weight: bold;
        font-size: 30px;
      }
    }
  }
  &__buy-btn{
    width: 300px;
    padding: 15px;
    font-size: 14px;
    text-transform: uppercase;
    cursor:pointer;
    display: inline-block;
    text-align: center;
    @include respond-to(small){
      width: 100%;
    }
  }
}

.radio, .checkbox{
  font-size: 18px !important;
  color:#666;
  @include respond-to(small){
    font-size: 13px !important;
  }
  i{

  }
}

.breadcrumb{
  font-size: 13px;
  li{
    padding: 3px 0;
    margin-top: 5px;
    @include respond-to(small){
      display: block;
    }
  }
}

#topNav{
  background: #333;
}
#topBar{
  background: #fff;
}

#topMain.nav-pills>li>a{
    font-size: 15px;
    color: #fff;
    text-decoration: none;
    border-radius: 0;
    //font-family:'Raleway';
  //text-transform: uppercase;
    //text-transformgulp: uppercase;
}

.radio input:checked + i, .checkbox input:checked + i, .toggle input:checked + i{
  border-color: rgba(0,0,0,.5);
}

input{
  height: 50px !important;
  border-width: 1px !important;
}

.subservice{
  &__product-list{
    list-style-type: none;
    padding-left: 0;
    margin-left: 0;
    margin: 20px 0;
    li{
      display: block;
      padding: 15px;
      font-size: 18px;
      text-align: center;
      cursor:pointer;
      margin-bottom: 5px;
      border: 1px solid #eee;
      background: #f5f5f5;
      &:hover{
        //background: $category;
      }
      &.active{
        color:#fff !important;
      }
    }
  }
}

#swipe_area{
  height: 100vh;
  width: 50px;
  position: fixed;
  left:0;
  top:0;
  z-index:100;
  @include respond-to(tabletsmore){
    display: none;
  }
}

.modal{
  &-content{
    padding: 40px;
  }
  &-body{
    padding: 0;
  }
  &-header{
    padding: 0;
    margin-bottom: 30px;
    border:none;
  }
  &-footer{
    margin-top: 20px;
    padding: 0;
    border:none;
    text-align: left;
    .btn{
      margin-left: 0;
    }
  }
  &-orderform{
    textarea{
      display: block;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      width: 100%;;
      padding: 8px 10px;
      outline: 0;
      border-width: 1px;
      border-style: solid;
      border-radius: 0;
      border-color:#e5e5e5;
      font-weight: normal;
      background: #fff;
      color: #404040;
      appearance: normal;
      -moz-appearance: none;
      -webkit-appearance: none;
      &:hover,&:focus{
        border-color: #c6c6c6;
      }
    }
  }
}

.flexthis:after, .flexthis:before {
   content: normal !important; // IE doesn't support `initial`
}

.row {
    &:before {
        content: none;
    }

    &:after {
        content: '';
    }
}

.pasx{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    /* height: 100vh; */
    z-index: 1999;
    display: none;
}

.item-with-icon{
  display: flex;
  flex-direction: row;
  padding: 15px 15px 15px 70px;
  margin-bottom: 5px;
  position: relative;
  &__label{
    display: block;
    font-size: 14px;
    color:#777;
    margin-top: 10px;
  }
  &__ul{
    padding: 0;
    list-style-type: none;
  }
  &_black{
    background: #222;
    color:#fff;
  }
  &_bluegrey{
    background: #ECEFF1;
    color:#455A64;
    .item-with-icon__icon{

      color: #ffffff !important;
    }
  }
  &__text{
    //display: inline-block;
    //vertical-align: middle;
    flex:1;
    font-weight: 500;
    font-size: 15px;
  }
  &__icon{
    display: flex;
    font-size: 23px;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    width: 50px;
    text-align: center;
    background: #39638d;
    text-align: center;
    height: 100%;
    position: absolute;
    left: 0;
    top:0;
  }
}

.overlay_light{
  .topimg{
    &__h{
      color:#222;
    }
    &__subh{
      color:#222;
    }
  }
}

.service{
  &__desc{
    color: #80868a;
    display: block;
    margin-top: 20px;

    p{
      margin-bottom: 0;
    }
  }
  &__h{
    font-size: 26px;
    font-weight: 900;
    @include respond-to(mobile){
      font-size: 20px;
    }
    &-wrap{

    }

    margin-bottom: 10px;
  }
  &__header{
    padding: 50px;
    background: #fff;
    margin-bottom: 30px;
  }
  &-box{
    padding: 0 30px;
    background: #fff;
    margin-bottom: 10px;
  }
}

.service-block{
  display: block;
  padding: 5px 10px;
  //border-bottom:1px solid #e5e5e5;
  color:#777;
  position: relative;
  left:0;
  &:last-child{
    border-bottom:none;
  }
  &:hover{
    background: #f5f5f5;
    left: 3px;
  }
  &__h{
    font-size: 15px;
  }
  &__desc{
    font-size: 11px;
    color:#aaa;
  }
  &__btn{

  }
  &__link{

  }
}

.service-box{
  color: #777;
  background: #fff !important;
  transition:all ease .3s;
  display: block !important;
  i{
    top:-5px !important;
    transition:all ease .3s;
  }
  &__title{
    font-size: 22px;
    font-weight: bold;
  }
  &:hover{
    color: #fff !important;
    i{
      color:#fff !important;
    }

  }
}

.category-to-service{
  &__item{
    background: #ffffff;
    padding:15px 0;
    border-bottom: 1px solid #eee;
    &:last-child{
      border-bottom: none;
    }
  }
  &__h{
    font-size: 16px;
    margin-bottom: 10px;
    display: block;
    line-height: 1.3em;
    &:hover{
      text-decoration: underline;
      transition:all ease .3s;
    };
  }
}

h1,h2,h3,h4,h5,h6{
  font-family:'Raleway','Roboto Condensed','Open Sans',HelveticaNeueCyr,Arial;
  text-transform: uppercase;
  font-weight:bold;
}

.greybg{
  background: $greybg;
  div.heading-title h1, div.heading-title h2, div.heading-title h3, div.heading-title h4, div.heading-title h5, div.heading-title h6{
    background: $greybg !important;
  }
}

.h-anim{
  transition:all ease .3s;
  &:hover{
    transition:all ease .3s;
  }
}

.title-bg{
  display: inline-block;
  padding:5px 10px;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 15px;
}

.innerpage{
  &-content{
    background: $greybg;
    @include respond-to(small){
      //background: #ffffff;
    }
  }
  &-with-bg{
    >.container{
      position: relative;
      top: -100px;
      z-index: 100;
    }
  }
}

.item{
  &-description{
    margin-bottom: 40px;
  }
}

.share{
  &__title{
    display: block;
    font-size: 10px;
    color:#777;
  }
}


.item-list{
  &__block{
    background: #fff;
    //border: 1px solid #e9e9e9;
    //padding: 20px;
    //height: 200px;
    margin-bottom: 30px;
    @include respond-to(mobile){
      margin-bottom: 15px;
    }
  }
  &__news-text-content{
    padding: 15px;
    display: block;
    text-decoration: none;
    position: relative;
  }
  &__share{
    position: absolute;
    right: 15px;
    bottom: 15px;
    @include respond-to(small){
      position: relative;
      right: 0;
      bottom: 0;
      margin-top: 10px;
      text-align: right;
    }
  }
  &__text-content{
    //margin-top: 30px;
    padding: 15px 10px;
    text-align: center;
    display: block;
    text-decoration: none;
    &:hover{
      //background: #333;
      //transform: scale(1.02,1.02);
      //background: rgba(0,0,0,.2);
      .item-list__title{
        text-decoration: underline;
        @include respond-to(mobile){
          text-decoration: none;
        }
      }

    }
    @include respond-to(mobile){
      text-align: left;
      border:1px solid $brdblue;
      transition:all ease .3s;
      &:hover{
        background: #f6f6f6;
        transition:all ease .3s;
      }
    }
  }
  &__title{
    font-size: 13px;
    display: block;
    margin-bottom: 5px;
    color:$link;
    font-weight: bold;
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    text-decoration: none;
  }
  &__img{
    max-width:100%;
    opacity:1;
    transition:all ease .3s;
    &:hover{
      opacity:0.85;
      transition:all ease .3s;
    }
  }
  &__row{
    margin-bottom: 40px;
    //border-bottom: 1px solid $brdblue;
  }
  &__description{
    font-size: 14px;
    color:#777;
  }
  &__date{
    margin-top: 25px;
    font-size: 12px;
    i{
      display: inline-block;
      margin-right: 10px;
    }
  }
}

#footer ul.footer-links>li{
  font-weight: normal;
  padding-left: 15px;
  line-height: 1.3em;
}

.footer-links{
  a{
    text-decoration: underline !important;
    color:$blue !important;
    text-indent:-15px;
    font-size: 0.95em;
    &:hover{
      color: #888 !important;
      text-decoration: none !important;
    }
    &:before{
      padding-right: 0 !important;
    }
  }
}

.owl-carousel{
  &_news{
    div{
      text-align: left !important;

    }
    .owl-buttons{
      .owl-prev,.owl-next{
        background: rgba(255,255,255,.8);
        text-align: center !important;
      }
    }
    .owl-wrapper{
      margin-left: -10px !important;
    }
  }
  &_iconsblock{
    .iconblock-with-text__img_wrap{
      height: 150px;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      img{
        display: block;
        max-height: 100%;
        margin: 0 auto;
        width: auto;
      }
    }
  }
}


.wblock{
  background: #fff;
  padding: 50px;
  margin-bottom: 20px;
  &_description{

    p{
      margin-bottom: 0;
    }
  }
  @include respond-to(mobile){
    padding: 40px 30px;
  }
  &_shadow{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  }
}

.btn{
  text-decoration: none !important;
  &_bordered{
    display: inline-block;
    padding: 7px 15px;
    text-decoration: none !important;
    &_blue{
      border:1px solid lighten($blue,40%);
      color:$blue;
      background-color: transparent;
      transition:all ease .3s;

      &:hover{
        background:$blue;
        color:#fff;
        transition:all ease .3s;
      }
    }
  }
  &_solid{
    display: inline-block !important;
    padding: 7px 15px !important;
    text-decoration: none !important;
    &_blue{
      background: $blue !important;
      color:#fff !important;
    }
  }
}


.partners{
  &__one{
    margin-bottom:60px;
    overflow: hidden;
    @include respond-to(small){
      text-align: center;
      }
    &:last-child{
      margin-bottom: 20px;
    }
    &__img-wrapper{
      display: flex;
      height: 80px;
      width: 100px;
      justify-content: center;
      align-items: center;
      @include respond-to(small){
        width: auto;
        height: auto;
        display: block;
        max-width: 220px;
        margin:0 auto;
      }
    }
    img{
      display: inline-block;
      border-radius: 3px;
      max-height: 80px;
      max-width: 100%;
      @include respond-to(small){
        text-align: center;
        margin-bottom: 20px;
        max-height: 150px;
      }
      //@include respond-to(small){
      //  display: none;
      //}
    }
    &_left{
      float: left;
      display: inline-block;
      @include respond-to(small){
        float:none;
        display: block;
        padding: 0;
      }
    }
    &_right{
      padding-left: 100px;
      margin-left: 20px;
      @include respond-to(small){
        padding-left: 0;
        margin-left: 0;
      }
    }
    &_title{
      @extend .newsblock__one__title;
      display: block;
      vertical-align: middle;
    }
    &_shortdesc{
      display: block;
      margin-top: 5px;
      color:#888;
    }
    &_more{
      //@extend .btn_standart;
      //display: inline-block;
      //margin-top: 10px;
    }
  }
}



.smi-list{
  padding: 15px 50px;
}

.just-list{
  &_white{
    background: #ffffff;
  }
  &__date{
    font-size: 10px;
    color:#777;
  }
  &__item{
    padding: 15px 0;
    border-bottom:2px dashed #ccc;
    &:first-child{
      padding-top: 0;
    }
    &:last-child{
      border-bottom:none;
      padding-bottom: 0;
    }
  }
  &__name{
    font-weight: bold;
    font-size: 16px;
    color: #333;
  }
  &__link{
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
    font-family:'Raleway',"Open Sans","Arial";
    text-transform: uppercase;
  }
  &__desc{
    font-size: 12px;
    color:#777;
  }
  &__source{
    font-size: 11px;
    margin-top: 10px;
    color:#777;
    a{
      color:$link;
      text-decoration: underline;
      &:hover{
        text-decoration: none;
      }
    }
  }
}


.newstext{
  position: relative;
  &__body{
    margin-bottom: 30px;
  }
  &__date{
    font-size: 1.1em;
  }
  &__source{
    a{
      font-size: 12px;
    }
  }
  &__img{
    border:1px solid #ddd;
    margin-bottom: 30px;
    img{
      max-width: 100%;
    }
  }
  &__h{
    font-size: 24px !important;
    @include respond-to(mobile){
      font-size: 20px !important;
    }
  }
}

#sidepanel{
  a{
    text-transform: uppercase;
  }
}

#sidepanel ul ul{
  padding-left: 10px;
}


.list-group-item.list-toggle{
  >ul.collapse{
    //padding-left: 20px;
  }
}


.newsblock{
  padding: 50px;
  background: #fff;
  .pagination-wrap{
    text-align: center;
  }
  &_all{

  }
  &__title{
    color: #ffffff;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
  }
  &__one{
    padding:30px 0;
    border-bottom:1px solid #ddd;
    &:last-child{
      border-bottom:none;
    }
    &__content{
    padding-left: 110px;
      @include respond-to(small){
        padding-left: 0;
      }
    }
    &__img{
      float: left;
      @include respond-to(small){
        display: none;
      }
    }
    &__title{
      font-weight: bold;
      font-size: 16px;
      color:#333;
      text-decoration: underline;
      &:hover{
        text-decoration: none;
      }
    }
    &__date{
      color:#aaa;
    }
    &__preview{
      color:#333;
    }
  }
}

.parallax h1{
  font-size: 42px !important;
}

.parallax{
  background-position:center bottom;
  background-repeat:no-repeat;
}

.row-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
  flex-wrap: wrap;
  @include respond-to(mobile){
    display: block;
  }
}
.row-flex > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

.index_slider_logo{
  width: 200px;
  margin:0 auto;
  img{
    display: block;
    max-width: 100%;
  }
}

#slider-light{
    display: block;
    padding: 0;
    margin: 0;
    box-shadow: none;
    background-color: #151515;
    overflow: hidden !important;
    border-bottom: 0;
    z-index: 0;
    -webkit-transition: all 0s;
    -moz-transition: all 0s;
    -o-transition: all 0s;
    transition: all 0s;
}

#slider-light h1, #slider-light h2, #slider-light h3, #slider-light h4, #slider-light h5, #slider-light h6, #slider-light p{
  color:#fff;
}

.index-slider{
  border-bottom: 1px solid rgba(0,0,0,.1) !important;
  background-color: #1b5790;
  &__title{
    font-size: 50px;
    @include respond-to(mobile){
      font-size: 40px;
    }
    @include respond-to(small){
      font-size: 26px;
    }
  }
  /* IE9, iOS 3.2+ */
  background-image:url("../images/index_bg_image.png"), url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMTAwJSIgeTI9IjEwMCUiPjxzdG9wIHN0b3AtY29sb3I9IiMzMzdmYzEiIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIwIi8+PHN0b3Agc3RvcC1jb2xvcj0iIzAzMmU1ZSIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjEiLz48L2xpbmVhckdyYWRpZW50PjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjdnNnZykiIC8+PC9zdmc+);
  background-image:url("../images/index_bg_image.png"), -webkit-gradient(linear, 0% 0%, 100% 100%,color-stop(0, rgb(51, 127, 193)),color-stop(1, rgb(3, 46, 94)));
  /* Android 2.3 */
  background-image:url("../images/index_bg_image.png"), -webkit-repeating-linear-gradient(top left,rgb(51, 127, 193) 0%,rgb(3, 46, 94) 100%);
  /* IE10+ */
  background-image:url("../images/index_bg_image.png"), -ms-repeating-linear-gradient(top left,rgb(51, 127, 193) 0%,rgb(3, 46, 94) 100%);
  background-image:url("../images/index_bg_image.png"), repeating-linear-gradient(to bottom right,rgb(51, 127, 193) 0%,rgb(3, 46, 94) 100%);
  background-repeat: no-repeat;
  background-position: 95% bottom;
  background-size:660px, auto !important;
  background-attachment: scroll, fixed;
  @include respond-to(desktopsless){
    /* IE9, iOS 3.2+ */
  background-image:url("../images/index_bg_image2.png"), url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMTAwJSIgeTI9IjEwMCUiPjxzdG9wIHN0b3AtY29sb3I9IiMzMzdmYzEiIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIwIi8+PHN0b3Agc3RvcC1jb2xvcj0iIzAzMmU1ZSIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjEiLz48L2xpbmVhckdyYWRpZW50PjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjdnNnZykiIC8+PC9zdmc+);
  background-image:url("../images/index_bg_image2.png"), -webkit-gradient(linear, 0% 0%, 100% 100%,color-stop(0, rgb(51, 127, 193)),color-stop(1, rgb(3, 46, 94)));
  /* Android 2.3 */
  background-image:url("../images/index_bg_image2.png"), -webkit-repeating-linear-gradient(top left,rgb(51, 127, 193) 0%,rgb(3, 46, 94) 100%);
  /* IE10+ */
  background-image:url("../images/index_bg_image2.png"), -ms-repeating-linear-gradient(top left,rgb(51, 127, 193) 0%,rgb(3, 46, 94) 100%);
  background-image:url("../images/index_bg_image2.png"), repeating-linear-gradient(to bottom right,rgb(51, 127, 193) 0%,rgb(3, 46, 94) 100%);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size:500px, auto !important;
  background-attachment: scroll, fixed;
  }
  @include respond-to(tablets){
    /* IE9, iOS 3.2+ */
  background-image:url("../images/index_bg_image.png"), url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMTAwJSIgeTI9IjEwMCUiPjxzdG9wIHN0b3AtY29sb3I9IiMzMzdmYzEiIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIwIi8+PHN0b3Agc3RvcC1jb2xvcj0iIzAzMmU1ZSIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjEiLz48L2xpbmVhckdyYWRpZW50PjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjdnNnZykiIC8+PC9zdmc+);
  background-image:url("../images/index_bg_image.png"), -webkit-gradient(linear, 0% 0%, 100% 100%,color-stop(0, rgb(51, 127, 193)),color-stop(1, rgb(3, 46, 94)));
  /* Android 2.3 */
  background-image:url("../images/index_bg_image.png"), -webkit-repeating-linear-gradient(top left,rgb(51, 127, 193) 0%,rgb(3, 46, 94) 100%);
  /* IE10+ */
  background-image:url("../images/index_bg_image.png"), -ms-repeating-linear-gradient(top left,rgb(51, 127, 193) 0%,rgb(3, 46, 94) 100%);
  background-image:url("../images/index_bg_image.png"), repeating-linear-gradient(to bottom right,rgb(51, 127, 193) 0%,rgb(3, 46, 94) 100%);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size:450px, auto !important;
  background-attachment: scroll, fixed;
  }
  @include respond-to(mobile){
    /* IE9, iOS 3.2+ */
  background-image:url("../images/index_bg_image.png"), url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMTAwJSIgeTI9IjEwMCUiPjxzdG9wIHN0b3AtY29sb3I9IiMzMzdmYzEiIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIwIi8+PHN0b3Agc3RvcC1jb2xvcj0iIzAzMmU1ZSIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjEiLz48L2xpbmVhckdyYWRpZW50PjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjdnNnZykiIC8+PC9zdmc+);
  background-image:url("../images/index_bg_image.png"), -webkit-gradient(linear, 0% 0%, 100% 100%,color-stop(0, rgb(51, 127, 193)),color-stop(1, rgb(3, 46, 94)));
  /* Android 2.3 */
  background-image:url("../images/index_bg_image.png"), -webkit-repeating-linear-gradient(top left,rgb(51, 127, 193) 0%,rgb(3, 46, 94) 100%);
  /* IE10+ */
  background-image:url("../images/index_bg_image.png"), -ms-repeating-linear-gradient(top left,rgb(51, 127, 193) 0%,rgb(3, 46, 94) 100%);
  background-image:url("../images/index_bg_image.png"), repeating-linear-gradient(to bottom right,rgb(51, 127, 193) 0%,rgb(3, 46, 94) 100%);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size:400px, auto !important;
  background-attachment: scroll, fixed;
  }

}

.index-slider2{
  background:url("../images/bg1.jpg") no-repeat center;
  -webkit-background-size: cover;
  background-size: cover;
  box-shadow: 0 -10px 30px rgba(0,0,0,.7) inset !important;
  height: 600px;
  @include respond-to(tabledesktop){
    height: 500px;
  }
  @include respond-to(tablets){
    height: 450px;
  }
  @include respond-to(mobile){
    height: 370px;
  }

}

.sidemenu_more{
    display: inline-block;
    margin-top: 10px;
    padding: 12px 7px;
    text-align: center;
    background: $blue;
    font-size: 11px;
    margin-bottom: 15px;
    &:hover{
      background: lighten($blue,10%);
      color:#fff !important;
    }
}

.slider-content{
  @include respond-to(mobile){
    text-align: center;
    vertical-align: top;
    padding-top: 80px;
  }
  @include respond-to(tablets){
    text-align: center;
    vertical-align: top;
    padding-top: 80px;
  }
}

.category-menu-name{
  text-transform: uppercase !important;
  margin: 0 15px 10px !important;
  font-size: 16px !important;
  padding: 7px 0 !important;
  text-decoration: none !important;
  font-family: 'Raleway';
  font-weight: bold !important;
  &:hover{
    background: none !important;
  }
}

.category-service-link{
  font-size: 14px !important;
  line-height: 20px !important;
  text-transform: none !important;
}

.dropdown-menu-block{
  padding-bottom: 30px;
  &_more{
    text-decoration: underline;
    color:$link !important;
    display: inline-block !important;
    font-size: 14px !important;
  }
}

.timeline_center li h3{
  margin-bottom: 5px !important;
  font-size: 24px !important;
  text-transform: none !important;
}


.nav-main{
  .dropdown-menu{
    padding: 20px 0;
  }
}

.breadcrumb{
  background: $greybg !important;
}

.badge{
  text-decoration: none;
  font-weight:lighter;
}

.badge.badge-light {
  color: #777;
  background: #ecf0f1 !important;
  font-weight: normal;
  &:hover,&:focus,&:active,&:visited{
    background: #e3e9ea !important;
    color:#444;
  }
}

.select2{
  width: 100% !important;
}

.toast-top-full-width{
  top:8% !important;
}

.gradient_blue {
    background-color: #1b5790;
    /* IE9, iOS 3.2+ */
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0idnNnZyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMTAwJSIgeTI9IjEwMCUiPjxzdG9wIHN0b3AtY29sb3I9IiMzMzdmYzEiIHN0b3Atb3BhY2l0eT0iMSIgb2Zmc2V0PSIwIi8+PHN0b3Agc3RvcC1jb2xvcj0iIzAzMmU1ZSIgc3RvcC1vcGFjaXR5PSIxIiBvZmZzZXQ9IjEiLz48L2xpbmVhckdyYWRpZW50PjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjdnNnZykiIC8+PC9zdmc+);
    background-image: -webkit-gradient(linear, 0% 0%, 100% 100%,color-stop(0, rgb(51, 127, 193)),color-stop(1, rgb(3, 46, 94)));
    /* Android 2.3 */
    background-image: -webkit-repeating-linear-gradient(top left,rgb(51, 127, 193) 0%,rgb(3, 46, 94) 100%);
    /* IE10+ */
    background-image: repeating-linear-gradient(to bottom right,rgb(51, 127, 193) 0%,rgb(3, 46, 94) 100%);
    background-image: -ms-repeating-linear-gradient(top left,rgb(51, 127, 193) 0%,rgb(3, 46, 94) 100%);
}

.column-with-icon{
  text-align: center;
  [class^="svg-"], [class*=" svg-"]{
    font-size: 120px;
  }
  &_index{
    margin-bottom: 30px;
  }
  &__title{
      font-size: 18px;
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    display: block;
    /* padding: 0 30px; */
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 5px;
    font-weight: bold;
  }
  &__subtitle{
    font-size: 14px;
    color:#777;
  }

}

.index-news-smi-section{
  background: #f4f4f4;
}


.index-features{
  &-section{
    padding: 0;
  }
    &__block{
      padding:40px 0;
      &:nth-child(2n+1){
        background: $greybg2;
      }
    }
    &__h{
      font-family: 'Raleway', sans-serif;
      text-transform: uppercase;
      margin-bottom: 60px;
      &_green{
        &:after{
          content:'';
          height: 3px;
          width: 80px;
          background: #6f9a59;
          display: block;
          margin: 3px auto;
        }
      }
      &_red{
        &:after{
          content:'';
          height: 3px;
          width: 80px;
          background: #B12C30;
          display: block;
          margin: 3px auto;
        }
      }
      &_yellow{
        &:after{
          content:'';
          height: 3px;
          width: 80px;
          background: #EAB50C;
          display: block;
          margin: 3px auto;
        }
      }
      &_blue{
        &:after{
          content:'';
          height: 3px;
          width: 80px;
          background: $blue;
          display: block;
          margin: 3px auto;
        }
      }
      &_wrap{
        text-align: center;
      }
    }
}


.bordered-block{
  border:2px solid #e2e8ef;
  padding:10px 30px 40px;
  //margin-bottom: 60px;
  &__h{
    background: #fff;
    display: inline-block;
    position: relative;
    top:-30px;
    padding: 0 20px 2px;
    &_green{
      background: #709b59;
      color:#fff;
    }
    &_darkblue{
      background: #35383f;
      color:#fff;
    }
    &_yellow{
      background: #eab50c;
      color:#333;
    }
    &_wrap{
      text-align: center;
    }
  }
}

.dots-divider{
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  div{
    width: 10px;
    height: 10px;
    background: #cfd7e0;
    display: inline-block;
    margin-right: 4px;
    border-radius:50%;
    &:last-child{
      margin-right: 0;
    }
  }
}

/* IE8- CSS hack */
@media \0screen\,screen\9 {
    .gradient_blue {
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff337fc1",endColorstr="#ff032e5e",GradientType=0);
    }
}


//.row-height > [class*='col-']{
//    transition-property:all;
//    transition-duration:.6s;
//    transition-timing-function:ease;
//}


.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
}

.mytoggle{
  &__content{
    padding: 20px;
    background: $greybg2;
  }
}

.toggle{
  margin-top: 5px !important;
  label{
    padding: 0;
  }
  &-content{
    background: $greybg2 !important;
  }
  i{
    transition:all ease .2s;
  }
  &.active{
    i{
      transform: rotate(90deg);
      transition:all ease .2s;
    }
  }
  .list-icon__text{
    transition:all ease .3s;
    &:hover{
      background: #555;
      transition:all ease .3s;
    }
  }
}


.list-icon{
  display: table;
  &__text{
    padding: 10px;
    display: table-cell;
    position: relative;
    z-index:3;
    float:left;
    width: 100%;
    background: #333333;
    color:#fff;
  }
  &__icon{
    width: 1%;
    white-space:nowrap;
    vertical-align: middle;
    display: table-cell;
    padding:0 10px;
    i{
      font-size:24px;
    }
    &_blue{
      background: $blue;
      i{
        color:#fff;
      }
    }
  }
}

.branch{
  &__category{
    &_h{

    }
  }
}


.owl-carousel .owl-wrapper:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}
/* display none until init */
.owl-carousel{
	display: none;
	position: relative;
	width: 100%;
	-ms-touch-action: pan-y;
}
.owl-carousel .owl-wrapper{
	display: none;
	position: relative;
	-webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper-outer{
	overflow: hidden;
	position: relative;
	width: 100%;
    z-index: 0;
}
.owl-carousel .owl-wrapper-outer.autoHeight{
	-webkit-transition: height 500ms ease-in-out;
	-moz-transition: height 500ms ease-in-out;
	-ms-transition: height 500ms ease-in-out;
	-o-transition: height 500ms ease-in-out;
	transition: height 500ms ease-in-out;
}

.owl-carousel .owl-item{
	float: left;
}
.owl-controls .owl-page,
.owl-controls .owl-buttons div{
	cursor: pointer;
}
.owl-controls {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.owl-carousel.featured .owl-controls .owl-prev {
	margin-right:3px;
}

/* fix */
.owl-carousel  .owl-wrapper,
.owl-carousel  .owl-item{
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility:    hidden;
	-ms-backface-visibility:     hidden;
  -webkit-transform: translate3d(0,0,0);
  -moz-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
}
	/*
	 *  Owl Carousel CSS3 Transitions
	 *  v1.3.2
	 */
	.owl-origin {
		-webkit-perspective: 1200px;
		-webkit-perspective-origin-x : 50%;
		-webkit-perspective-origin-y : 50%;
		-moz-perspective : 1200px;
		-moz-perspective-origin-x : 50%;
		-moz-perspective-origin-y : 50%;
		perspective : 1200px;
	}
	/* fade */
	.owl-fade-out {
	  z-index: 10;
	  -webkit-animation: fadeOut .7s both ease;
	  -moz-animation: fadeOut .7s both ease;
	  animation: fadeOut .7s both ease;
	}
	.owl-fade-in {
	  -webkit-animation: fadeIn .7s both ease;
	  -moz-animation: fadeIn .7s both ease;
	  animation: fadeIn .7s both ease;
	}
	/* backSlide */
	.owl-backSlide-out {
	  -webkit-animation: backSlideOut 1s both ease;
	  -moz-animation: backSlideOut 1s both ease;
	  animation: backSlideOut 1s both ease;
	}
	.owl-backSlide-in {
	  -webkit-animation: backSlideIn 1s both ease;
	  -moz-animation: backSlideIn 1s both ease;
	  animation: backSlideIn 1s both ease;
	}
	/* goDown */
	.owl-goDown-out {
	  -webkit-animation: scaleToFade .7s ease both;
	  -moz-animation: scaleToFade .7s ease both;
	  animation: scaleToFade .7s ease both;
	}
	.owl-goDown-in {
	  -webkit-animation: goDown .6s ease both;
	  -moz-animation: goDown .6s ease both;
	  animation: goDown .6s ease both;
	}
	/* scaleUp */
	.owl-fadeUp-in {
	  -webkit-animation: scaleUpFrom .5s ease both;
	  -moz-animation: scaleUpFrom .5s ease both;
	  animation: scaleUpFrom .5s ease both;
	}

	.owl-fadeUp-out {
	  -webkit-animation: scaleUpTo .5s ease both;
	  -moz-animation: scaleUpTo .5s ease both;
	  animation: scaleUpTo .5s ease both;
	}
/* Keyframes */
@-webkit-keyframes empty {
  0% {opacity: 1}
}
@-moz-keyframes empty {
  0% {opacity: 1}
}
@keyframes empty {
  0% {opacity: 1}
}
@-webkit-keyframes fadeIn {
  0% { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes fadeIn {
  0% { opacity:0; }
  100% { opacity:1; }
}
@keyframes fadeIn {
  0% { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes fadeOut {
  0% { opacity:1; }
  100% { opacity:0; }
}
@-moz-keyframes fadeOut {
  0% { opacity:1; }
  100% { opacity:0; }
}
@keyframes fadeOut {
  0% { opacity:1; }
  100% { opacity:0; }
}
@-webkit-keyframes backSlideOut {
  25% { opacity: .5; -webkit-transform: translateZ(-500px); }
  75% { opacity: .5; -webkit-transform: translateZ(-500px) translateX(-200%); }
  100% { opacity: .5; -webkit-transform: translateZ(-500px) translateX(-200%); }
}
@-moz-keyframes backSlideOut {
  25% { opacity: .5; -moz-transform: translateZ(-500px); }
  75% { opacity: .5; -moz-transform: translateZ(-500px) translateX(-200%); }
  100% { opacity: .5; -moz-transform: translateZ(-500px) translateX(-200%); }
}
@keyframes backSlideOut {
  25% { opacity: .5; transform: translateZ(-500px); }
  75% { opacity: .5; transform: translateZ(-500px) translateX(-200%); }
  100% { opacity: .5; transform: translateZ(-500px) translateX(-200%); }
}
@-webkit-keyframes backSlideIn {
  0%, 25% { opacity: .5; -webkit-transform: translateZ(-500px) translateX(200%); }
  75% { opacity: .5; -webkit-transform: translateZ(-500px); }
  100% { opacity: 1; -webkit-transform: translateZ(0) translateX(0); }
}
@-moz-keyframes backSlideIn {
  0%, 25% { opacity: .5; -moz-transform: translateZ(-500px) translateX(200%); }
  75% { opacity: .5; -moz-transform: translateZ(-500px); }
  100% { opacity: 1; -moz-transform: translateZ(0) translateX(0); }
}
@keyframes backSlideIn {
  0%, 25% { opacity: .5; transform: translateZ(-500px) translateX(200%); }
  75% { opacity: .5; transform: translateZ(-500px); }
  100% { opacity: 1; transform: translateZ(0) translateX(0); }
}
@-webkit-keyframes scaleToFade {
  to { opacity: 0; -webkit-transform: scale(.8); }
}
@-moz-keyframes scaleToFade {
  to { opacity: 0; -moz-transform: scale(.8); }
}
@keyframes scaleToFade {
  to { opacity: 0; transform: scale(.8); }
}
@-webkit-keyframes goDown {
  from { -webkit-transform: translateY(-100%); }
}
@-moz-keyframes goDown {
  from { -moz-transform: translateY(-100%); }
}
@keyframes goDown {
  from { transform: translateY(-100%); }
}

@-webkit-keyframes scaleUpFrom {
  from { opacity: 0; -webkit-transform: scale(1.5); }
}
@-moz-keyframes scaleUpFrom {
  from { opacity: 0; -moz-transform: scale(1.5); }
}
@keyframes scaleUpFrom {
  from { opacity: 0; transform: scale(1.5); }
}

@-webkit-keyframes scaleUpTo {
  to { opacity: 0; -webkit-transform: scale(1.5); }
}
@-moz-keyframes scaleUpTo {
  to { opacity: 0; -moz-transform: scale(1.5); }
}
@keyframes scaleUpTo {
  to { opacity: 0; transform: scale(1.5); }
}

	/**
		CUSTOM REWRITE
	**/
	.owl-carousel {
		overflow:hidden;
		margin-bottom:20px;
		position:relative;
	}
	.owl-carousel.owl-padding-0 .owl-item {
		padding:0 !important;
	}
	.owl-carousel.owl-padding-1 .owl-item {
		padding:0 1px;
	}
	.owl-carousel.owl-padding-2 .owl-item {
		padding:0 2px;
	}
	.owl-carousel.owl-padding-3 .owl-item {
		padding:0 3px;
	}
	.owl-carousel.owl-padding-6 .owl-item {
		padding:0 6px;
	}
	.owl-carousel.owl-padding-10 .owl-item {
		padding:0 10px;
	}
	.owl-carousel.owl-padding-15 .owl-item {
		padding:0 15px;
	}
	.owl-carousel.owl-padding-20 .owl-item {
		padding:0 20px;
	}

	/* Cause width problems - better to leave left/right margins
	.owl-carousel .owl-item:first-child {
		padding-left:0;
	}
	.owl-carousel .owl-item:last-child {
		padding-right:0;
	}
	*/

	.owl-carousel img {
		display:inline-block;
	}
	.owl-carousel.buttons-autohide .owl-buttons {
		filter: Alpha(Opacity=0);
		opacity:0;

		-webkit-transition: opacity 0.4s;
		   -moz-transition: opacity 0.4s;
			 -o-transition: opacity 0.4s;
				transition: opacity 0.4s;
	}
		.owl-carousel.buttons-autohide:hover .owl-buttons {
			filter: Alpha(Opacity=100);
			opacity:1;
		}

	.owl-theme .owl-controls .owl-buttons div {
		color:#121212;
		background:rgba(255,255,255,.8);
		border:#fff 1px solid;

		opacity:1;
		filter: Alpha(Opacity=100);

		-webkit-border-radius: 3px;
		   -moz-border-radius: 3px;
				border-radius: 3px;
	}
		section.dark .owl-theme .owl-controls .owl-buttons div {
			color:#fff;
			background:#212121;
			border:#212121 1px solid;
		}

	.controlls-over .owl-controls .owl-prev {
		position: absolute;
		top: 50%;
		margin-top: -28px;
		right:auto;
		left: -13px;
		zoom: 1;
		width: 36px;
		height: 36px;
		opacity: 0;
	}
	.controlls-over .owl-controls .owl-next {
		position: absolute;
		top: 50%;
		margin-top: -28px;
		left:auto;
		right: -13px;
		zoom: 1;
		width: 36px;
		height: 36px;
		opacity: 0;
	}



	.controlls-over .owl-pagination {
		text-align:center;
		position:absolute; width:60%; margin:auto;
		bottom:-20px; left:0; right:0; z-index:1;
	}
	.owl-theme.controlls-over .owl-controls .owl-page {
		background:rgba(0,0,0,0.3);
		margin-bottom:30px;
		position:relative;
		display:inline-block;

	}
	.owl-theme.controlls-over .owl-controls .owl-page:first-child {
		-webkit-border-top-left-radius: 10px;
		-webkit-border-bottom-left-radius: 10px;
		-moz-border-radius-topleft: 10px;
		-moz-border-radius-bottomleft: 10px;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
	}
	.owl-theme.controlls-over .owl-controls .owl-page:last-child {
		-webkit-border-top-right-radius: 10px;
		-webkit-border-bottom-right-radius: 10px;
		-moz-border-radius-topright: 10px;
		-moz-border-radius-bottomright: 10px;
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
	}
	.owl-theme.controlls-over .owl-controls .owl-page span {
		background:#fff;
	}
	.bottom-pagination .owl-controls .owl-page {
		margin-bottom:-40px !important;
	}


	/* top text caption */
	.owl-carousel .owl-item div {
		position:relative;
		text-align:center;
	}
	.owl-carousel .owl-caption {
		padding:10px;
		position:absolute !important;
		left:0; top:0; right:0;
		margin-top:0; max-width:100%;
		background:rgba(0,0,0,0.3);
		display:block; color:#fff;
	}

	.owl-carousel .owl-caption p {
		color:#fff;
		font-size:13px;
		line-height:20px;
		padding:0; margin:0;
	}
	.owl-carousel .owl-caption h1,
	.owl-carousel .owl-caption h1 a,
	.owl-carousel .owl-caption h2,
	.owl-carousel .owl-caption h2 a,
	.owl-carousel .owl-caption h3,
	.owl-carousel .owl-caption h3 a {
		color:#fff;
		font-size:21px;
		line-height:21px;
		font-weight:bold;
		margin-bottom:10px;
	}
	.owl-carousel .owl-caption a {
		color:#fff;
		font-weight:bold;
	}

	.owl-carousel.controls-hover-only .owl-controls {
		filter: alpha(opacity=0);
		opacity: 0;

		-webkit-transition: opacity 0.4s;
		   -moz-transition: opacity 0.4s;
			 -o-transition: opacity 0.4s;
				transition: opacity 0.4s;
	}
	.slider:hover .owl-carousel.controls-hover-only .owl-controls,
	.owl-carousel.controls-hover-only:hover .owl-controls {
		filter: alpha(opacity=100);
		opacity: 1;
	}


/* Featured Item */
.owl-carousel.featured .owl-featured-item {
	width:99%; padding-bottom:15px;
	text-align:center;
	display:block;

	webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}
.owl-carousel.featured .owl-featured-item:hover {
	background-color: #eaeaea;
}
.owl-carousel.featured .owl-featured-item a.figure {
	margin:0; padding:0;
	display:block;
	width:100%;
	height:auto;
	overflow:hidden;
	text-align:center;
	z-index:0;
	position:relative;
}

.owl-carousel.featured .owl-featured-item a.figure>img {
	height:auto !important;
	width:100% !important;
	max-width: 100% !important;
	vertical-align:top;
}
.owl-carousel.featured .owl-featured-detail {
	position:relative;
	padding-top:6px;
}

.owl-carousel.featured .owl-featured-detail:after {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 10px solid #fff;
	left: 50%;
	margin-left: -10px;
	top: -10px;
	z-index:10;

	webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

	.owl-carousel.featured .owl-featured-item:hover>.owl-featured-detail:after {
		border-bottom: 10px solid #eaeaea;
	}

.owl-carousel.featured .owl-featured-detail>a.featured-title {
	color:#333;
	margin-top:6px;
	display:block;
}


.owl-carousel.featured .owl-featured-detail>span.price {
	display:block;
	margin-bottom:6px;
}


.owl-carousel.featured {
	margin-top:6px;
}
.owl-carousel.featured .owl-controls.clickable {
	top:0 !important;
	margin-top:-45px;
	position:absolute;
	right:0;
}
h2.owl-featured {
	font-size:16px;
	line-height:19px;
	border-bottom:rgba(0,0,0,0.2) 1px dashed;
	padding-bottom:6px;
	margin-bottom:10px;;
}

.owl-carousel.featured a.figure>span {
	position:absolute;
	left:0; right:0; top:0; bottom:0;
	background-color:rgba(0,0,0,0.3);
	filter: alpha(opacity=0);
	opacity: 0;

	-webkit-transition: opacity 0.3s;
	   -moz-transition: opacity 0.3s;
		 -o-transition: opacity 0.3s;
			transition: opacity 0.3s;
}
.owl-carousel.featured a.figure>span>i {
	color:#333;
	position:absolute;
	left:50%; top:50%;
	background:#fff;
	font-size:21px;
	width:50px; height:50px;
	line-height: 50px !important;
	text-align:center;
	margin-left:-20px;
	margin-top:-20px;

	-webkit-transition: all 0.3s;
	   -moz-transition: all 0.3s;
		 -o-transition: all 0.3s;
			transition: all 0.3s;

	-webkit-border-bottom-right-radius: 20px;
		-webkit-border-top-left-radius: 20px;
		-moz-border-radius-bottomright: 20px;
			-moz-border-radius-topleft: 20px;
			border-bottom-right-radius: 20px;
				border-top-left-radius: 20px;
}
.owl-carousel.featured a.figure:hover>span {
	filter: alpha(opacity=100);
	opacity: 1;
}

.owl-carousel.featured {
	overflow:inherit !important;
}
.owl-carousel.featured .owl-prev,
.owl-carousel.featured .owl-next {
	display:inline-block !important;
	color:#777 !important;
	width:30px !important;
	height:30px !important;
	line-height:30px !important;
	font-size:20px !important;
}
.owl-carousel.featured .owl-prev:hover,
.owl-carousel.featured .owl-next:hover {
	color:#121212 !important;
}


section.dark .owl-carousel.featured .owl-featured-detail>a.featured-title {
	color:#fff;
}
section.dark .owl-carousel.featured .owl-featured-detail:after {
	border-bottom-color: #212121;
}
section.dark .owl-carousel.featured .owl-featured-item:hover {
	background-color: #111;
}
section.dark .owl-carousel.featured .owl-featured-item:hover>.owl-featured-detail:after {
	border-bottom-color: #111;
}
section.dark .owl-carousel.featured .owl-prev,
section.dark .owl-carousel.featured .owl-next {
	color:#777 !important
}
section.dark .owl-carousel.featured .owl-prev:hover,
section.dark .owl-carousel.featured .owl-next:hover {
	color:#fff !important
}
section.dark h2.owl-featured {
	border-bottom-color:#666;
}

@media only screen and (max-width: 768px) {
/*
	.owl-carousel.featured .owl-controls {
		display:none !important
	}
*/
}



/* buttons bottom */
.owl-carousel.buttons-bottom  .owl-controls{
	top:auto !important;
}
.owl-carousel.buttons-bottom .owl-next,
.owl-carousel.buttons-bottom .owl-prev {
	margin-top:-20px !important;
}

/* special carousel title */
.owl-carousel .owl-carousel-caption {
	background-color:rgba(0,0,0,0.5);
	position:absolute !important;
	top:0; left:0; right:0;
	color:#fff; padding:20px;
	height:96px; overflow:hidden;
}
.owl-carousel .owl-carousel-caption.top {
	top:0;
	bottom:auto;
}
.owl-carousel .owl-carousel-caption.bottom {
	top:auto;
	bottom:29px;
}
.owl-carousel .owl-carousel-caption h2,
.owl-carousel .owl-carousel-caption h3,
.owl-carousel .owl-carousel-caption h4 {
	color:#fff;
	font-size:16px;
	line-height:16px;
	margin-bottom:10px;
	font-weight:bold;
	overflow:hidden;
	display:block;
	width:100%;
	text-overflow:ellipsis;
	white-space: nowrap;
}
.owl-carousel .owl-carousel-caption p {
	color:#fff;
	font-size:12px;
	line-height:15px;
	padding:0; margin:0;
}

.owl-theme .owl-controls .owl-buttons div {
	/* padding:6px 13px; */
}
.owl-controls .owl-page,
.owl-controls .owl-buttons div{
	cursor: pointer;
}

.controlls-over .owl-controls .owl-prev {
	margin-left:10px;
}

.controlls-over .owl-controls .owl-next {
	margin-right:10px;
}


/* Styling Pagination*/
.owl-theme .owl-controls .owl-page{
	display: inline-block;
	zoom: 1;
	*display: inline;/*IE7 life-saver */
}
.owl-theme .owl-controls .owl-page span {
	display: block;
	width: 20px;
	height: 5px;
	margin: 5px 7px;
	filter: Alpha(Opacity=50);/*IE7 fix*/
	opacity: 0.5;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	background: #869791;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span{
	filter: Alpha(Opacity=100);/*IE7 fix*/
	opacity: 1;
}


/* If PaginationNumbers is true */

.owl-theme .owl-controls .owl-page span.owl-numbers{
	height: auto;
	width: auto;
	color: #FFF;
	padding: 2px 10px;
	font-size: 12px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
}

@media only screen and (max-width: 600px) {
	.owl-pagination {
		display:none;
	}
}


/** 36. Price Table
 **************************************************************** **/
div.price-table {
	background:rgba(0,0,0,0.03);
	margin:30px 0;
	text-align:center;
	padding-bottom:30px;
	border-left:#fff 1px solid;
}

div.row.pricetable-container {
	padding:0 15px;
}
	div.price-table h3 {
		font-size:25px;
		line-height:25px;
		padding:30px 0;
		border-bottom: rgba(0,0,0,0.1) 2px solid;
		text-transform:uppercase;
		font-weight:300;
	}
	div.price-table p, div.price-table .circle {
		color: #666;
		font-size: 36px;
		line-height:36px;
		padding: 30px 0;
		font-weight: 400;
		width: 150px;
		height: 150px;
		padding-top: 53px;
		display: inline-block;
		background-color: rgba(0,0,0,0.05);
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		margin-top:0;
	}
	div.price-table .circle>div {
		display:block;
		font-size:10px;
		line-height:16px;
		font-weight:300;
		text-transform:uppercase;
	}
	div.price-table ul {
		margin:0;
		padding:0;
	}
	div.price-table ul li {
		list-style:none;
		font-size:12px;
		border-bottom: rgba(0,0,0,0.1) 1px solid;
		padding:8px;
		text-transform:uppercase;
	}
	div.price-table.popular,
	div.price-table.popular ul li,
	div.price-table.popular p,
	div.price-table.popular .circle,
	div.price-table.popular .circle>div,
	div.price-table.popular p span,
	div.price-table.popular h3 {
		color:#fff;
	}
	div.price-table.popular {
		background:#676767;
	}
	div.price-table .btn {
		margin-top:30px;
	}
	div.price-table .btn-primary {
		background-color:#333;
		border:0;
	}

section.dark div.price-table {
	border-left-color:#212121;
	background-color:#444;
}
section.dark div.price-table.popular {
	background-color:#666;
}
section.dark div.price-table h3 {
	border-bottom-color:rgba(255,255,255,0.1);
}
section.dark div.price-table .btn {
	color:#fff;
}

#topNav button.btn-mobile{
      padding: 9px 10px 4px;
}

/* mega price table */
div.mega-price-table {
	margin-top:60px;
}
div.mega-price-table .btn .caret.pull-right {
	margin-top:8px;
}
div.mega-price-table .pricing-title,
div.mega-price-table .pricing-head {
	color:#fff;
	text-align:center;
	background-color:rgba(0,0,0,0.6);
	height:75px;
}
	div.mega-price-table .pricing-title {
		background-color:transparent !important;
		padding:15px 0 0 0;
		margin:0;
		height:165px;
	}
	div.mega-price-table .pricing-title h3 {
		font-size:35px;
		line-height:35px;
		margin-bottom:10px;
	}

div.mega-price-table .pricing-head h3 {
	margin-bottom:3px;
	display:block;
	color:#fff;
	font-size:30px;
	padding-top:12px;
	height:36px;
	font-weight:300;
}
div.mega-price-table h4 {
	display:block;
	text-align:center;
	font-size:60px;
	padding:20px 0; margin:0;
	font-weight:400;
	color:#666;
	height:85;
	background-color:rgba(0,0,0,0.03);
	font-weight:300;
}
div.mega-price-table .pricing:hover h4 {
	color:#333;
	text-align:center;
}
div.mega-price-table .pricing h4 sup,
div.mega-price-table .pricing h4 sub {
	font-size:34px;
}
div.mega-price-table .pricing-head small {
	font-size:12px;
	line-height:40px;
	display:block;
	color:rgba(255,255,255,0.7);
	font-weight:300;
	font-family:'Open Sans', Arial, Hevletica, sans-serif;
}

div.mega-price-table .pricing-desc li,
div.mega-price-table ul.pricing-table li {
	padding: 10px;
	font-style: normal;
	min-height: 41px;
	text-align:center;
}
	div.mega-price-table ul li.alternate {
		background-color:rgba(0,0,0,0.03);
	}

div.mega-price-table {
	padding-left:15px;
	padding-right:15px;
}
div.mega-price-table div {
	padding:0;
}
div.mega-price-table .pricing {
	margin-top: 1px;
	margin-left: 1px;
	background: rgba(0,0,0,0.03);
}
	section.dark div.mega-price-table ul li.alternate,
	section.dark div.mega-price-table .pricing {
		background: rgba(255,255,255, 0.05);
	}
div.mega-price-table .pricing-desc div,
div.mega-price-table .pricing-desc li {
	text-align:left !important;
}
div.mega-price-table .btn,
div.mega-price-table .list-unstyled,
div.mega-price-table .btn-group,
div.mega-price-table .btn-toolbar {
	margin:0;

	-webkit-border-radius: 0;
	   -moz-border-radius: 0;
			border-radius: 0;
}
div.mega-price-table .pricing.popular h4 small {
	color:#fff;
}
div.mega-price-table .pricing.popular {
	background-color:#676767;
}
div.mega-price-table .pricing.popular,
div.mega-price-table .pricing.popular h3,
div.mega-price-table .pricing.popular h4,
div.mega-price-table .pricing.popular li,
div.mega-price-table .pricing.popular div,
div.mega-price-table  .pricing.popular .pricing-table i.fa {
	color:#fff !important;
}
div.mega-price-table .dropdown-menu {
	width:100%;
}

/* clean price */
.price-clean {
	padding: 30px 10px;
	text-align: center;
	position: relative;
	border: 1px solid #D0D6DF;
	font-family: Arial, Helvetica, sans-serif;

	-webkit-box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.05);
	   -moz-box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.05);
		 -o-box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.05);
			box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.05);
}
	.price-clean h4 {
		font-size: 60px;
		line-height:60px;
		font-weight: 300;
		margin: 0;
		color: #547698;
	}
	.price-clean h4 sup {
		position: relative;
		font-size: 20px;
		line-height:25px;
		vertical-align: top;
		top: 3px;
	}
	.price-clean h4 em {
		font-size: 14px;
		font-style:normal;
	}
	.price-clean h5 {
		text-transform: uppercase;
		font-weight: 300;
		margin: 0;
		font-size: 15px;
		color: #BACDD6;
		letter-spacing: 2px;
	}
	.price-clean p {
		line-height: 1.5em;
		color: #526066;
		margin-bottom: 0;
	}



@media only screen and (max-width: 992px) {
	.price-clean {
		margin-bottom:30px;
	}
	.col-md-5th .price-clean h4 {
		font-size: 40px;
		line-height:40px;
	}
	.col-md-5th .price-clean h4 em {
		font-size:11px;
	}
}
@media only screen and (max-width: 768px) {
	.price-clean h4 {
		font-size: 60px;
		line-height:60px;
	}
	.price-clean h4 em {
		font-size: 14px;
	}
}


/** 30. Toastr
*************************************************** **/
.toast-title {
  font-weight: bold;
}
.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: #ffffff;
}
.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}
.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}
/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}
#toast-container {
  position: fixed;
  z-index: 999999;
  /*overrides*/

}
#toast-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#toast-container > div {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  color: #ffffff;
  opacity: 0.93;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
#toast-container > :hover {
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer;
}
#toast-container > .toast-info {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
}
#toast-container > .toast-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important;
}
#toast-container > .toast-success {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important;
}
#toast-container > .toast-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important;
}
#toast-container.toast-top-center > div,
#toast-container.toast-bottom-center > div {
  width: 300px;
  margin: auto;
}
#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
  width: 96%;
  margin: auto;
}
.toast {
  background-color: #030303;
}
#toast-container .toast-primary {
	padding:15px;
}
.toast-primary {
  border:0;
  background-color: #333;
}
.toast-success {
  background-color: #51a351;
}
.toast-error {
  background-color: #bd362f;
}
.toast-info {
  background-color: #2f96b4;
}
.toast-warning {
  background-color: #f89406;
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  background-color: #000000;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}
/*Responsive Design*/
@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}

section.padding-md{
  padding: 50px 0 !important;
}

.custom-file-upload-hidden {
  display: none;
  visibility: hidden;
  position: absolute;
  left: -9999px;
}

.file-upload-wrapper {
	position: relative;
	margin-bottom: 5px;
}

.file-upload-input {
	width: 300px;
	font-size: 16px;
	padding: 11px 17px;

	-moz-transition: all 0.2s ease-in;
	-o-transition: all 0.2s ease-in;
	-webkit-transition: all 0.2s ease-in;
	transition: all 0.2s ease-in;

	float: left;
	/* IE 9 Fix */
}

.fancy-file-upload {
	position:relative;
	height:40px;
	overflow:hidden;
	display:block;
	margin-bottom:3px;
}
.fancy-file-upload>span.button {
	color:#fff;
	background-color:#333;
	position: absolute;
	top: 4px;
	right: 4px;
	top:4px;
	bottom:4px;
	line-height: 34px;
	padding: 0 16px;
	z-index: 10;

    -webkit-border-radius: 3px;
       -moz-border-radius: 3px;
			border-radius: 3px;
}
	.fancy-file-upload.fancy-file-primary>span.button {
		background-color:#333;
	}
	.fancy-file-upload.fancy-file-success>span.button {
		background-color:#4cae4c;
	}
	.fancy-file-upload.fancy-file-danger>span.button {
		background-color:#d43f3a;
	}
	.fancy-file-upload.fancy-file-warning>span.button {
		background-color:#eea236;
	}
	.fancy-file-upload.fancy-file-info>span.button {
		background-color:#46b8da;
	}
	.fancy-file-upload.fancy-file-default>span.button {
		color:#666;
		background-color:rgba(0,0,0,0.1);
	}

.fancy-file-upload>input[type=text] {
	background-color:transparent;
	padding-left: 36px;

    -webkit-border-radius: 3px;
       -moz-border-radius: 3px;
			border-radius: 3px;
}
.fancy-file-upload>input[type=file] {
	width: 100%;
	height: 100%;
	cursor: pointer;
	padding: 8px 10px;
	position: absolute;
	-moz-opacity: 0;
	opacity: 0;
	z-index: 11;
	bottom: 0;
	right: 0;

    -webkit-border-radius: 3px;
       -moz-border-radius: 3px;
			border-radius: 3px;
}
.fancy-file-upload>i {
	position:absolute;
	top: -1px;
	width: 42px;
	height: 42px;
	color: inherit;
	line-height: 42px;
	position: absolute;
	text-align: center;

    color:#888;

	z-index: 10;
}

.landing-lg-btn{
  display: inline-block;
  font-size: 24px !important;
  color:#fff;

}

.size-25{
  font-size: 25px !important;
}


.grey-grad-bg{
  background: #51565e;
  background: -moz-linear-gradient(-45deg, #51565e 0%, #000000 100%);
  background: -webkit-linear-gradient(-45deg, #51565e 0%,#000000 100%);
  background: linear-gradient(135deg, #51565e 0%,#000000 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#51565e', endColorstr='#000000',GradientType=1 );
}


.txt-white{
  color:#fff !important;
}



.landing-style{
  .breadcrumb-section{
    position: absolute;
    left: 0;
    right: 0;
    @include respond-to(small){
      position: relative;
      display: none;
    }
    //background: rgba(0,0,0,.75);
    z-index: 5;
    &.breadcrumb{
      &__light{
        li.active{
          color:#ccc;
        }
        li>a{
          color:#fff;
        }
        li+li:before{
          color:#aaa !important;
        }

      }
      &__dark{
        li.active{
          color:#777;
        }
        li>a{
          color:#222;
        }
        li+li:before{
          color:#777 !important;
        }
      }
    }
    .breadcrumb{
      background: none !important;
      margin-bottom: 0;
      padding: 8px 30px !important;
      li{
        @include respond-to(small){
          font-size: 10px;
      }
      }
    }
  }
  .list-with-marker{
    ul{
      list-style-type: none;
      padding-left: 0;
      font-size: 15px;
      li{
        padding-left: 20px;
        text-indent: -20px;
        line-height: 1.5em;
        display: block;
        margin-bottom: 10px;
      }
    }
    &_blue{
      li{
        &:before{
          background-color: $blue;
          border-radius: 50%;
          content: "";
          display: inline-block;
          margin-right: 10px;
          margin-bottom: 2px;
          height: 7px;
          width: 7px;
        }
      }

    }
  }
  .btn{
    display: inline-block;
    padding: 20px 40px;
    border-radius: 5px;
          height: auto;
      line-height:inherit;
    font-size: 18px;
    @include respond-to(small){
      padding: 15px 20px;
      font-size: 15px;

    }
  }
  .heading{
    margin-bottom: 35px;
    text-align: center;
    &-title{
      font-size: 30px;
      margin-bottom: 0;
      @include respond-to(small){
        font-size: 22px !important;
      }
      @include respond-to(mobile){
        font-size: 25px;
      }
      @include respond-to(tablets){
        font-size: 27px;
        }
    }

  }
  .subheading{
      font-size: 18px;
      margin: 0;
      @include respond-to(small){
        font-size: 14px !important;
      }
      @include respond-to(mobile){
        font-size: 16px;
      }
      @include respond-to(tablets){
        font-size: 16px;
      }
    }
  section div.row>div{
    margin-bottom: 0;
  }
}

.container-fluid.container-for-map{
  padding-left: 0;
  padding-right: 0;
}

.padding{
  &-lg{
    padding: 120px 0;
  }
  &-md{
    padding: 80px 0;
  }
  &-sm{
    padding: 50px 0;
  }
}

.fa-angle-left{
  &:before{
     font-family: fontawesome;
    content: "\f104";
  }
}

.fa-angle-right{
  &:before{
     font-family: fontawesome;
    content: "\f105";
  }
}


$pb_blue : #668bb8;
$pb_green : #5b874c;
$pb_greyblue : #546E7A;
$pb_orange : #F57C00;
$pb_rosered : #df3b4e;
$pb_darkred : #ad3f39;

@mixin section_theme($color){
  background: $color ;
  background: -moz-linear-gradient(-45deg, $color 0%, darken($color, 20%) 100%) ;
  background: -webkit-linear-gradient(-45deg, $color 0%,darken($color, 20%) 100%) ;
  background: linear-gradient(135deg, $color 0%,darken($color, 20%) 100%) ;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$color, endColorstr=darken($color, 20%),GradientType=1 );
  .item-list__block{
    background: transparent;
    .dots-divider>div{
      background: $color !important;
    }
  }
  .item-list__description{
    color:inherit;
  }
  .heading-title{
    color:inherit;
  }
  .item-list__text-content{
    color:inherit;
    &:hover{
      background: inherit;
    }
  }


}

.service-theme{
  &_default{
      .breadcrumb a{
        color:$link !important;
        &:hover{
          color:lighten($link,10%) !important;
        }
      }
    .breadcrumb li.active{
      color:#fbfbfb !important;
    }
      .heading:after{
        background: $pb_blue !important;
      }
  }

}



.section{
  &_white{
    background: #ffffff;
    .btn{
      color:#fff;
      background: $blue;

    }
  }
  &_anim-gradient{
    color: #fff;
	background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #4a75a7);
	background-size: 400% 400% !important;
	-webkit-animation: Gradient 15s ease infinite;
	-moz-animation: Gradient 15s ease infinite;
	animation: Gradient 15s ease infinite;

    .btn{
      background: transparent !important;
      border: 2px solid #fff;
      &:hover{
        background: rgba(255,255,255,.15) !important;
        transform: scale(1.05,1.05);
      }
    }

    .iconblock-with-text{
      color:#fff;
      &__title{
        color: #ffffff;
      }
      &__description{
        color: #ffffff;
      }
    }
  }
  &_anim-gradient_black{
    color: #fff;
	background: linear-gradient(-45deg, #333, #111, #222);
	background-size: 400% 400% !important;
	-webkit-animation: Gradient 25s ease infinite;
	-moz-animation: Gradient 25s ease infinite;
	animation: Gradient 25s ease infinite;
    .btn{
      background: transparent !important;
      border: 2px solid #fff;
      &:hover{
        background: rgba(255,255,255,.15) !important;
        transform: scale(1.05,1.05);
      }
    }
    .iconblock-with-text{
      color:#fff;
      &__title{
        color: #ffffff;
      }
      &__description{
        color: #ffffff;
      }
    }
  }
  &_dark{
    background: #51565e ;
    background: -moz-linear-gradient(-45deg, #51565e 0%, #000000 100%) ;
    background: -webkit-linear-gradient(-45deg, #51565e 0%,#000000 100%) ;
    background: linear-gradient(135deg, #51565e 0%,#000000 100%) ;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#51565e', endColorstr='#000000',GradientType=1 );
    color:#fff;
    .btn{
      color:#fff;
      background: $blue;
    }
    .heading-title{
      color:#fff;
    }
    .price-price{
      color: #ffffff !important;
    }
    .iconblock-with-text{
      color:#fff;
      &__title{
        color: #ffffff;
      }
      &__description{
        color: #ffffff;
      }
    }
  }
  &_light{
    background: #f8f8f8;
    background: -moz-linear-gradient(-45deg, #f8f8f8 0%, #f0f0f0 100%) ;
    background: -webkit-linear-gradient(-45deg, #f8f8f8 0%,#f0f0f0 100%) ;
    background: linear-gradient(135deg, #f8f8f8 0%,#f0f0f0 100%) ;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8f8f8', endColorstr='#f0f0f0',GradientType=1 );
    color:#455A64;
    .btn{
      color:#fff;
      background: $blue;
    }
  }
  &_blue{
    @include section_theme($pb_blue);
    color:#fff;
    .iconblock-with-text{
      &__title{
        color:#fff;
      }
      a{
        color:#fff;
      }
      &__description{
        color:#f0f0f0;
      }
    }
    .service__desc{
      color:#fff;
    }
  }
}

#footer>.container{
  margin-bottom: 0;
}

#footer address .footer-sprite{
  margin-bottom: 10px;
}


::-webkit-scrollbar-track {border-radius: 4px;}
::-webkit-scrollbar {width: 7px; background: #aaa;}
::-webkit-scrollbar-thumb {border-radius: 4px;background: lighten(#666666,10%);}
// iframe:hover::-webkit-scrollbar-thumb {background: #6a7d9b;}
::-webkit-scrollbar-track-piece { background-color: rgba(255,255,255,.5);}


.btn_order-wrap{
  @include respond-to(small){

  }
}

.mainimgblock{
  &-section{
    &_center{
      display: flex;
      align-items:center;
      .content{
        width: 80%;
        margin: 0 auto;
        @include respond-to(small){
          width: 100% !important;
        }
        .btn_order-wrap{
          text-align: center;
        }
      }
    }
    &_left{
      display: flex;
      align-items:center;
      .content{
        text-align: left;
        width: 60%;
        @include respond-to(mobile){
          width: 100%;
        }
      }
      .heading{
        text-align: left;
      }
      .btn_order-wrap{
        text-align: left;
      }
    }
    &_right{
      display: flex;
      align-items:center;
      .content{
        text-align: right;
        width: 70%;
        float: right;
        @include respond-to(mobile){
          width: 100%;
        }
      }
      .heading{
        text-align: right;
      }
      .btn_order-wrap{
        text-align: right;
      }
    }
    height: 600px;
    @include respond-to(small){
      height: auto !important;
      //&_dark{
      //  background: #51565e ;
      //  background: -moz-linear-gradient(-45deg, #51565e 0%, #000000 100%) !important;
      //  background: -webkit-linear-gradient(-45deg, #51565e 0%,#000000 100%) !important;
      //  background: linear-gradient(135deg, #51565e 0%,#000000 100%) !important;
      //  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#51565e', endColorstr='#000000',GradientType=1 );
      //}
      //&_light{
      //  background: #f8f8f8 ;
      //  background: -moz-linear-gradient(-45deg, #f8f8f8 0%, #f0f0f0 100%) !important;
      //  background: -webkit-linear-gradient(-45deg, #f8f8f8 0%,#f0f0f0 100%) !important;
      //  background: linear-gradient(135deg, #f8f8f8 0%,#f0f0f0 100%) !important;
      //  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8f8f8', endColorstr='#f0f0f0',GradientType=1 );
      //  color:#455A64;
      //}
    }
    .heading-wrap{
      text-align: center;
    }
    .heading{
      &-title{
        font-size: 30px;
        margin-bottom: 5px;
        color:inherit;
        @include respond-to(small){
          font-size: 20px !important;
        }
        @include respond-to(mobile){
          font-size: 25px;
        }
        @include respond-to(tablets){
          font-size: 30px;
        }
      }


    }
    .subheading{
      font-size: 18px;
      color:inherit;
      @include respond-to(small){
        font-size: 14px !important;
      }
      @include respond-to(mobile){
        font-size: 16px;
      }
      @include respond-to(tablets){
        font-size: 16px;
      }
    }
    .btn_order{

      &-wrap{
        margin-top: 30px;
      }
    }
    &_dark{
       .heading{
         &-title{
          color:#fff;
         }
      }
      .subheading{
        color: #fff;
      }
    }
    &_light{
       .heading{
         &-title{
          color:#263238;
         }
      }
      .subheading{
        color: #546E7A;
      }
    }
  }

}

.advantages-section{
  .heading{
    &:after{
        content:'';
        display: block;
        width: 80px;
        height: 5px;
        background: $blue;
        margin:10px auto 0;
      }
    &-title{
      color:inherit;
    }
  }
  .subheading{
    color:inherit;
  }
  &.section_dark{
    .heading-title{
      color: #ffffff;
    }
    .subheding{
      color: #ffffff;
    }
    .iconblock-with-text__text{
      color: #ffffff;
    }
    .iconblock-with-text__title{
      color: #ffffff;
    }
    .iconblock-with-text__description{
      color: #ffffff;
    }
  }
}

.owl-item{
  .iconblock-with-text{
  text-align: center;
  margin-bottom: 30px;
  @include respond-to(mobile){
      display: block;
      text-align: center;
    &__text{
      margin-left: 0;
    }
  }
  }
}

.iconblock-with-text{
  text-align: center;
  margin-bottom: 30px;
  @include respond-to(mobile){
      display: flex;
      align-items: center;
      text-align: left;
      margin-bottom: 10px;
    &__text{
      margin-left: 30px;
    }
  }
  @include respond-to(small){
      display: block !important;
      align-items: center;
      text-align: center;
      margin-bottom: 50px;
    &__text{
      margin-left: 0;
    }
  }
  .heading{
      //font-size: 36px;
      //
      //@include respond-to(small){
      //  font-size: 20px !important;
      //}
      //@include respond-to(mobile){
      //  font-size: 25px;
      //}
      //@include respond-to(tablets){
      //  font-size: 30px;
      //}

    }
  &__img{
    max-width: 100%;
    display: block;
    padding: 10px;
    width: 120px;
    margin: 0 auto 10px;
    @include respond-to(small){
        margin: 0 auto 10px !important;
        padding: 0 !important;
        width: 80px !important;
    }
    @include respond-to(mobile){
        margin: 0 ;
        width: 100px;
    }
  }
  &__text{

  }

  &__title{
    margin-bottom: 0;
    color: #455A64;
    font-size: 17px;
    @include respond-to(small){
      font-size: 18px !important;
    }
    @include respond-to(mobile){
      font-size: 20px;
    }
    @include respond-to(tablets){
      font-size: 18px;
    }
    @include respond-to(tabletdesktop){
      font-size: 20px;
    }
  }
  &__description{
    color: #78909C;
    font-size: 15px;
    @include respond-to(small){
        font-size: 12px !important;
      }
      @include respond-to(mobile){
        font-size: 13px;
      }
      @include respond-to(tablets){
        font-size: 13px;
      }
  }
}

.imgwithlist-section{
  &.section_dark{
    .heading-title{
      color: #ffffff;
    }
    .subheding{
      color: #ffffff;
    }
    .iconblock-with-text__text{
      color: #ffffff;
    }
    .iconblock-with-text__title{
      color: #ffffff;
    }
    .iconblock-with-text__description{
      color: #ffffff;
    }
  }
  &_withicon{
    -webkit-background-size:contain !important;
    background-size:contain !important;
  }
  .heading{
    &-title{
      color:inherit;
    }
    margin-bottom: 40px;
  }
  .subheading{
    color:inherit;
  }
  .list{
    list-style-type: none;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
    li{
        display: flex;
        align-items:center;
        justify-content:flex-end;
        margin-bottom: 20px;
      }
      .li{

        &__text{

        }
        &__title{
          font-size: 20px;
          font-weight: bold;
        }
        &__desc{
          font-size: 14px;
        }
        &__icon{
          border:2px solid;
          border-radius:50%;
          padding: 20px;
          img{
            //max-width: 100%;
            width: 40px;
            height: 40px;
            display: block;
          }
        }
      }
    &_right{
      text-align: right;
      .heading-title{
        text-align: right;
      }
      .li__icon{
        margin-left: 20px;
      }

    }
    &_left{
      .heading-title{
        text-align: left;
      }
      .li__icon{
        margin-right: 20px;
      }
      li{
        justify-content: flex-start;
      }
    }

  }

  .heading{
    &-title{
      font-size: 30px;
      text-align: center;
      margin-bottom: 0;
      @include respond-to(small){
        font-size: 20px !important;
      }
      @include respond-to(mobile){
        font-size: 22px;
      }
      @include respond-to(tablets){
        font-size: 25px;
        }
    }

  }
}

.navbar-phone {
    margin-right: 20px;
    display: none;
    text-decoration: none;
    font-size: 18px;
    color: #fff;
    position: relative;
    top: 10px;
    opacity:0;
    transition:all ease .3s;
    @include respond-to(tablets){
      top:8px;
    }
  @include respond-to(mobile){
      top:8px;
    }
    @include respond-to(small){
      font-size: 0.8em;
      top:8px;
    }
  }

#header.fixed{
  .navbar-phone {
    display: inline-block;
    opacity:1;
    transition:all ease .3s;
  }
}

.navbar-landing{
    position: fixed;
    top: 50%;
    left: 0;
    z-index: 1;
    padding: 20px 0;

    /* opacity: 0.71; */
    border-radius: 0 4px 4px 0;
    border: none;
    /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.3); */
    transition: all ease .3s;
    /* -webkit-transform: translateY(-50%); */
    transform: translateY(-50%);
    transition: all .4s ease;
    &:hover{
      opacity:1;
      transition:all ease .3s;
    }
    @include respond-to(navbarlanding){
      display: none;
    }
    li{
      display:block;
      float:none !important;
      margin-bottom: 10px;
      padding: 0 10px;

      &:last-child{
        margin-bottom: 0;
      }
      a{
        color:#fff;
        position: relative;
        display: inline-block;
        font-size: 0.7em;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: 500;
        letter-spacing: 1.5px;
        background: none;
        border: none !important;
        writing-mode: vertical-lr;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        margin-right: 0 !important;
        line-height: 1 !important;
        padding: 0;
        &:hover,&:focus{
          background: none !important;
        }
      }
    }
    li.active > a{
      font-weight: bold;
      &:before{
        display: none;
      }
    }
}

.prices-section{
  .price-column{
    @include respond-to(mobile){
      margin-bottom: 40px;
    }
  }
  .price-title{
    text-transform: uppercase;
    font-family:'Raleway';
    font-size: 18px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    //top: 12px;
  }
  .price-price{
    font-size: 26px;
    font-weight: bold;
    display: block;
    color: #263238;
    margin-bottom: 15px;
    margin-top: 10px;
    position: relative;
    /* top: -4px; */
    line-height: 1em;
  }

  .popular-tag{
    border-radius: 10px;
    font-family: 'Open Sans', 'HelveticaNeueCyr', 'Arial', sans-serif;
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
    text-transform: none;
    padding: 2px 10px;
    color:#fff;
    background: #ff1744;
  }

}



.contact-section{
  padding: 0;
  .heading-title{
    //text-align: left;
  }
  .label{
    font-size: 14px;
    color:#90A4AE;
  }
  .item-with-icon{
    a{
      color:inherit;
      text-decoration: none;
    }
  }
}

.owl-prev{
  z-index: 1000;
}

.left-border-mobile{
  @include respond-to(mobile){
    padding:20px;
    border:1px solid #ddd;
    border-left:5px solid #333;
    margin:0 20px;
  }
  @include respond-to(tablets){
    padding:20px;
    border:1px solid #ddd;
    border-left:5px solid #333;
  }

}

.right-border-mobile{
  @include respond-to(mobile){
    border:1px solid #ddd;
    padding:20px;
    border-right:5px solid #333;
    margin:0 20px;
  }
  @include respond-to(tablets){
    border:1px solid #ddd;
    padding:20px;
    border-right:5px solid #333;
  }

}

.cases-section{
  .item-list{
    &__block{
      @include respond-to(mobile){
        display: flex;
          }
      @include respond-to(small){
          display: block;
        }
      figure{
        margin-bottom: 0;
        @include respond-to(mobile){
          width: 150px;
        }
        @include respond-to(small){
          width: 100%;
        }
        a{
          border:1px solid #ddd;

        }
      }

    }
    &__img{
      &_big{
        @include respond-to(mobile){
          display: none;
        }
        @include respond-to(small){
          display: block;
        }
      }
      &_small{
        display: none;
        @include respond-to(mobile){
          display: block;
        }
        @include respond-to(small){
          display: none;
        }
      }
    }
    .hoverable-block{
      @include respond-to(mobile){
        width: 150px;
          }
    }
    &__text-content{
      border:none;
      @include respond-to(mobile){
            flex:1;
          }
      &:hover{
        .item-list__title{
          text-decoration: none;
        }
      }
    }
    &__title{
      color:inherit;

    }
  }
}

.mouse{
  @include respond-to(small){
    display: none;
  }
}

.btn{
  &_normal{
    cursor:pointer;
    display: inline-block;
    padding: 7px 20px;
  }
  &_blue{
    background: $blue;
    color:#fff;
    transition:all ease .3s;
    &:hover{
      background: darken($blue,12%);
      color:#fff;
      transition:all ease .3s;
    }
  }
  &_pulse{
    animation: pound .6s infinite alternate;
  }
}

#topNav{
  .btn_normal{
    margin: 11px 0;
    @include respond-to(mobile){
      font-size: 13px;
      padding: 5px 12px;
      margin: 6px 0 0;
    }
    @include respond-to(tablets){
      font-size: 13px;
      padding: 5px 12px;
      margin: 8px 0 0;
    }
  }
  .social-icons-horizontal{
    margin-right: 16px;
  }
}

.fixed{
 #topNav{
  .btn_normal{
    margin:3px 0;
    @include respond-to(mobile){
      font-size: 13px;
      padding: 5px 12px;
      margin-top: 6px;
    }
    @include respond-to(tablets){
      font-size: 13px;
      padding: 5px 12px;
      margin: 8px 0 0;
    }
  }
}
}

.modal{
  p{
    font-weight: normal;
  }
}

.modal-footer{
  .btn{
    background: #333333 ;
    transition:all .3s ease;
    &:hover{
      background: #555555;
      transition:all .3s ease;
    }

  }
}


@keyframes pound {
        to { transform: scale(1.05); }
}

.anim{
  transition:all ease .3s;
  &:hover{
    transition:all ease .3s;
  }
}


@font-face {
    font-family: 'Ropa Sans Pro';
    src: local('Ropa Sans Pro'),  url('/static/fonts/RopaSansPro.woff') format('woff'), url('/static/fonts/RopaSansPro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto'),  url('/static/fonts/roboto.woff') format('woff'), url('/static/fonts/roboto.ttf') format('truetype'), url('/static/fonts/roboto.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url('/static/fonts/robotolight.woff2') format('woff2'), url('/static/fonts/robotolight.woff') format('woff'), url('/static/fonts/robotolight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}



@font-face {
    font-family: 'Raleway';
    src: url(/static/fonts/raleway-bold-webfont.woff2) format('woff2'),
         url(/static/fonts/raleway-bold-webfont.woff) format('woff');
    font-weight: 700;
    font-style: normal;

}




@font-face {
    font-family: 'Raleway';
    src: url(/static/fonts/raleway-light-webfont.woff2) format('woff2'),
         url(/static/fonts/raleway-light-webfont.woff) format('woff');
    font-weight: 300;
    font-style: normal;

}




@font-face {
    font-family: 'Raleway';
    src: url(/static/fonts/raleway-medium-webfont.woff2) format('woff2'),
         url(/static/fonts/raleway-medium-webfont.woff) format('woff');
    font-weight: 500;
    font-style: normal;

}




@font-face {
    font-family: 'Raleway';
    src: url(/static/fonts/raleway-regular-webfont.woff2) format('woff2'),
         url(/static/fonts/raleway-regular-webfont.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'Raleway';
    src: url(/static/fonts/raleway-semibold-webfont.woff2) format('woff2'),
         url(/static/fonts/raleway-semibold-webfont.woff) format('woff');
    font-weight: bold;
    font-style: normal;

}


@font-face {
    font-family: 'Raleway';
    src: url(/static/fonts/raleway-italic-webfont.woff2) format('woff2'),
         url(/static/fonts/raleway-italic-webfont.woff) format('woff');
    font-weight: normal;
    font-style: italic;

}
$small: 500px;
$mobile: 768px;
$tablets: 992px;
$desktops: 1200px;
$widescreen: 1600px;

@mixin respond-to($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile) { @content; }
  }
  @else if $media == tablets {
    @media only screen and (min-width: $mobile + 1) and (max-width: $tablets - 1) { @content; }
  }
  @else if $media == tabledesktop {
    @media only screen and (min-width: $tablets + 1) and (max-width: $desktops - 1) { @content; }
  }
  @else if $media == desktops {
    @media only screen and (min-width: $tablets) {  @content; }
  }
    @else if $media == desktopsless {
    @media only screen and (max-width: $desktops) { @content; }
  }
  @else if $media == small {
    @media only screen and (max-width: $small) { @content; }
  }
  @else if $media == mobilemore {
    @media only screen and (min-width: $mobile) { @content; }
  }
  @else if $media == tabletsmore {
    @media only screen and (min-width: $tablets) { @content; }
  }
  @else if $media == wide {
    @media only screen and (min-width: $widescreen) { @content; }
  }
  @else if $media == wideless {
    @media only screen and (max-width: $widescreen) { @content; }
  }
  @else if $media == navbarlanding {
    @media only screen and (max-width: 1050px) { @content; }
  }
}

@mixin whitelink{
  color:#fff;
  border-bottom: 1px solid rgba(255, 255, 255, .2);
  text-decoration: none;
  &:hover{
    color: #fff;
    border: none !important;
    text-decoration: none;
  }
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}
/* The Loader */
#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow: hidden;
  .loader__title{
    color:#bec0c3;
    font-size: 24px;
    text-align: center;
    display: block;
    position: relative;
    z-index:100;
  }
}
.no-js #loader-wrapper {
  display: none;
}

#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #609eac;
  -webkit-animation: spin 1.7s linear infinite;
          animation: spin 1.7s linear infinite;
  z-index: 11;
}
#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #487aa0;
  -webkit-animation: spin-reverse .6s linear infinite;
          animation: spin-reverse .6s linear infinite;
}
#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #37516e;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 51%;
  height: 100%;
  background: #fff;
  z-index: 10;
}

#loader-wrapper .loader-section.section-left {
  left: 0;
}

#loader-wrapper .loader-section.section-right {
  right: 0;
}

/* Loaded styles */
.loaded #loader-wrapper .loader-section.section-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader-wrapper .loader-section.section-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.loaded #loader-wrapper {
  visibility: hidden;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  -webkit-transition: all 0.3s 1s ease-out;
  transition: all 0.3s 1s ease-out;
}

