@font-face {
    font-family: 'Ropa Sans Pro';
    src: local('Ropa Sans Pro'),  url('/static/fonts/RopaSansPro.woff') format('woff'), url('/static/fonts/RopaSansPro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto'),  url('/static/fonts/roboto.woff') format('woff'), url('/static/fonts/roboto.ttf') format('truetype'), url('/static/fonts/roboto.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url('/static/fonts/robotolight.woff2') format('woff2'), url('/static/fonts/robotolight.woff') format('woff'), url('/static/fonts/robotolight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}



@font-face {
    font-family: 'Raleway';
    src: url(/static/fonts/raleway-bold-webfont.woff2) format('woff2'),
         url(/static/fonts/raleway-bold-webfont.woff) format('woff');
    font-weight: 700;
    font-style: normal;

}




@font-face {
    font-family: 'Raleway';
    src: url(/static/fonts/raleway-light-webfont.woff2) format('woff2'),
         url(/static/fonts/raleway-light-webfont.woff) format('woff');
    font-weight: 300;
    font-style: normal;

}




@font-face {
    font-family: 'Raleway';
    src: url(/static/fonts/raleway-medium-webfont.woff2) format('woff2'),
         url(/static/fonts/raleway-medium-webfont.woff) format('woff');
    font-weight: 500;
    font-style: normal;

}




@font-face {
    font-family: 'Raleway';
    src: url(/static/fonts/raleway-regular-webfont.woff2) format('woff2'),
         url(/static/fonts/raleway-regular-webfont.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'Raleway';
    src: url(/static/fonts/raleway-semibold-webfont.woff2) format('woff2'),
         url(/static/fonts/raleway-semibold-webfont.woff) format('woff');
    font-weight: bold;
    font-style: normal;

}


@font-face {
    font-family: 'Raleway';
    src: url(/static/fonts/raleway-italic-webfont.woff2) format('woff2'),
         url(/static/fonts/raleway-italic-webfont.woff) format('woff');
    font-weight: normal;
    font-style: italic;

}